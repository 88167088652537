import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as Yup from 'yup'
import * as websiteSectionRedux from '../index'

import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {WebsiteSectionBlockListModel} from '../Model/WebsiteSectionModel'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  subtitle: Yup.string().max(250, 'Subtitle exceeds 250 characters').nullable(),
  // link: Yup.string().required('Link is required'),
  // websiteBlockItemId: Yup.string().required('Website block is required'),
  status: Yup.string().required('Status is required'),
  showInHomepage: Yup.string().required('Show in homepage is required'),
})

const AddWebsiteSection = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [imageUrl, setImageUrl] = useState('')
  const {websiteBlockOption, loading, success} = useSelector((state: any) => state.websiteSection)

  useEffect(() => {
    dispatch(websiteSectionRedux.actions.getWebsiteBlockOption())
  }, [])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const homepageStatusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const productDocumentOptions = websiteBlockOption?.map((items: WebsiteSectionBlockListModel) => ({
    label: items.title,
    value: items.id,
    systemName: items.slug,
  }))

  useEffect(() => {
    if (success) {
      dispatch(websiteSectionRedux?.actions.getWebsiteSection(params))
      isEmpty(editSelectedData)
        ? toast.success('Website Section added successfully')
        : toast.success('Website Section edited successfully')
      dispatch(websiteSectionRedux?.actions?.resetWebsiteSection())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container' data-cy='apg-service-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Website Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                titleNp: '',
                subtitle: '',
                subtitleNp: '',
                link: '',
                image: '',
                description: '',
                descriptionNp: '',
                ctaButtonOne: '',
                ctaButtonOneLink: '',
                ctaButtonTwo: '',
                ctaButtonTwoLink: '',
                websiteBlockItemId: '',
                showInHomepage: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  showInHomePage: values?.showInHomepage === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    websiteSectionRedux.actions.updateWebsiteSection(
                      {...formData, id: editSelectedData?.id},
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(websiteSectionRedux.actions.addWebsiteSection(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'title',
                      'titleNp',
                      'subtitle',
                      'subtitleNp',
                      'link',
                      'image',
                      'description',
                      'descriptionNp',
                      'ctaButtonOne',
                      'ctaButtonOneLink',
                      'ctaButtonTwo',
                      'ctaButtonTwoLink',
                      'websiteBlockItemId',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue('websiteBlockItemId', editSelectedData?.websiteBlockItemId, false)
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'showInHomepage',
                      editSelectedData?.showInHomepage === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='apg-service-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title'
                            label='Title (EN)'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='apg-service-title-text-fields'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title (NP)'
                            label='Title (NP)'
                            name='titleNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='apg-service-title-np-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter subtitle'
                            label='Subtitle (EN)'
                            name='subtitle'
                            as='textarea'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='apg-service-sub-title-text-fields'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Subtitle (NP)'
                            label='Subtitle (NP)'
                            name='subtitleNp'
                            as='textarea'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='apg-service-sub-title-np-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Link'
                            label='Link'
                            name='link'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='apg-service-link-field'
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image'
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImageUrl}
                            value={values?.image}
                            required
                          />

                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImageUrl('')
                                      setFieldValue('image', '')
                                    }}
                                    data-cy='modal-thumbnail-remove'
                                  >
                                    Delete
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.image}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label={'Description (EN)'}
                            name='description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                            handleChange={handleChange}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label={'Description (NP)'}
                            name='descriptionNp'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.descriptionNp : ''
                            }
                            handleChange={handleChange}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button One'
                            label='CTA Button One'
                            name='ctaButtonOne'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button One Link'
                            label='CTA Button One Link'
                            name='ctaButtonOneLink'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button Two'
                            label='CTA Button Two'
                            name='ctaButtonTwo'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button Two Link'
                            label='CTA Button Two Link'
                            name='ctaButtonTwoLink'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select website block'
                            label='Website Block '
                            name='websiteBlockItemId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={productDocumentOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Show In Homepage
                          </label>

                          <div className='d-flex ms-5'>
                            {homepageStatusOptions?.map(
                              (status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='showInHomepage'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                  data-cy='apg-service-showInHomepage-radio'
                                />
                              )
                            )}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='showInHomepage'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='apg-service-status-radio'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddWebsiteSection
