import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {SortSearchActionModel, SortWebsiteServiceModel} from '../Model/WebsiteServiceModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getWebsiteServiceSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getWebsiteService, params)
    yield put(actions.getWebsiteServiceSuccess(response?.data?.data))
    yield put(actions.getWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getWebsiteServiceFinish())
  }
}

function* getAllWebsiteServiceSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllWebsiteService)
    yield put(actions.getAllWebsiteServiceSuccess(response?.data?.data))
    yield put(actions.getAllWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllWebsiteServiceFinish())
  }
}

function* addWebsiteServiceSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addWebsiteService, body)
    yield put(actions.addWebsiteServiceSuccess(response.data?.data))
    yield put(actions.addWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addWebsiteServiceFinish())
  }
}

function* enableWebsiteServiceSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableWebsiteService, selectedUsers)
    yield put(actions.enableWebsiteServiceSuccess(response?.data))
    yield put(actions.enableWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableWebsiteServiceFinish())
  }
}

function* disableWebsiteServiceSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableWebsiteService, selectedUsers)
    yield put(actions.disableWebsiteServiceSuccess(response?.data))
    yield put(actions.disableWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableWebsiteServiceFinish())
  }
}

function* singleEnableWebsiteServiceSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableWebsiteService, selectedUsers)
    yield put(actions.singleEnableWebsiteServiceSuccess(response?.data))
    yield put(actions.singleEnableWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableWebsiteServiceFinish())
  }
}

function* singleDisableWebsiteServiceSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableWebsiteService, selectedUsers)
    yield put(actions.singleDisableWebsiteServiceSuccess(response?.data))
    yield put(actions.singleDisableWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableWebsiteServiceFinish())
  }
}

function* updateWebsiteServiceSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateWebsiteService,
      body,
      action.payload?.id
    )
    yield put(actions.updateWebsiteServiceSuccess(response.data?.data))
    yield put(actions.updateWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateWebsiteServiceFinish())
  }
}

function* deleteWebsiteServiceSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteWebsiteService, body)
    yield put(actions.deleteWebsiteServiceSuccess(response.data?.data))
    yield put(actions.deleteWebsiteServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteWebsiteServiceFinish())
  }
}

function* sortWebsiteService(action: SortSearchActionModel) {
  try {
    const body: SortWebsiteServiceModel = action.payload
    const response: ResponseModel = yield call(service.sortWebsiteService, body)

    yield put({
      type: actionTypes.SORT_WEBSITE_SERVICE_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_WEBSITE_SERVICE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_WEBSITE_SERVICE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_WEBSITE_SERVICE_START, getWebsiteServiceSaga)
  yield takeLatest(actionTypes.GET_ALL_WEBSITE_SERVICE_START, getAllWebsiteServiceSaga)
  yield takeLatest(actionTypes.ADD_WEBSITE_SERVICE_START, addWebsiteServiceSaga)
  yield takeLatest(actionTypes.UPDATE_WEBSITE_SERVICE_START, updateWebsiteServiceSaga)
  yield takeLatest(actionTypes.DELETE_WEBSITE_SERVICE_START, deleteWebsiteServiceSaga)
  yield takeLatest(actionTypes.ENABLE_WEBSITE_SERVICE_REQUEST, enableWebsiteServiceSaga)
  yield takeLatest(actionTypes.DISABLE_WEBSITE_SERVICE_REQUEST, disableWebsiteServiceSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_WEBSITE_SERVICE_REQUEST,
    singleEnableWebsiteServiceSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_WEBSITE_SERVICE_REQUEST,
    singleDisableWebsiteServiceSaga
  )
  yield takeLatest(actionTypes.SORT_WEBSITE_SERVICE_START, sortWebsiteService)
}
