import {Action} from 'redux'
import {PayoutLocationModel} from '../Model/PayoutLocationModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialPayoutLocationState: IPayoutLocationState = {
  data: {
    payoutLocation: [],
    payoutLocations: [],
    meta: [],
  },
  sortPayoutLocationData: [],
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IPayoutLocationState {
  data?: {
    payoutLocation?: PayoutLocationModel[]
    payoutLocations?: PayoutLocationModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortPayoutLocationData?: IPayoutLocationState[]
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IPayoutLocationState = initialPayoutLocationState,
  action: ActionWithPayload<IPayoutLocationState>
) => {
  switch (action.type) {
    //GET PayoutLocation DATA
    case actionTypes.GET_PAYOUT_LOCATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PAYOUT_LOCATION_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_PAYOUT_LOCATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add PayoutLocation
    case actionTypes.ADD_PAYOUT_LOCATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_PAYOUT_LOCATION_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_PAYOUT_LOCATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_PAYOUT_LOCATION: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update PayoutLocation
    case actionTypes.UPDATE_PAYOUT_LOCATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_PAYOUT_LOCATION_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_PAYOUT_LOCATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_PAYOUT_LOCATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_PAYOUT_LOCATION_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_PAYOUT_LOCATION_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_PAYOUT_LOCATION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_PAYOUT_LOCATION_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_PAYOUT_LOCATION_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_PAYOUT_LOCATION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_PAYOUT_LOCATION_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_PAYOUT_LOCATION_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_PAYOUT_LOCATION_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_PAYOUT_LOCATION_SUCCESS: {
      const changedData: any = action.payload?.data?.payoutLocations
      let newData = state?.data?.payoutLocation?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, payoutLocation: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_PAYOUT_LOCATION_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_PAYOUT_LOCATION_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_PAYOUT_LOCATION_SUCCESS: {
      const changedData: any = action.payload?.data?.payoutLocations
      let newData = state?.data?.payoutLocation?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, payoutLocation: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_PAYOUT_LOCATION_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_PAYOUT_LOCATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_PAYOUT_LOCATION_SUCCESS: {
      return {
        ...state,
        sortPayoutLocationData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_PAYOUT_LOCATION_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_PAYOUT_LOCATION_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortPayoutLocationData: [],
      }
    }

    default:
      return state
  }
}
