import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getAllTutorial: (id: string = '') => ({
    type: actionTypes.GET_ALL_TUTORIAL_START,
    payload: id,
  }),

  getAllTutorialSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ALL_TUTORIAL_SUCCESS,
    payload: data,
  }),
  getAllTutorialFinish: () => ({
    type: actionTypes.GET_ALL_TUTORIAL_FINISH,
  }),

  getTutorial: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_TUTORIAL_START,
    payload: params,
  }),

  getTutorialSuccess: (data: any) => ({
    type: actionTypes.GET_TUTORIAL_SUCCESS,
    payload: data,
  }),
  getTutorialFinish: () => ({
    type: actionTypes.GET_TUTORIAL_FINISH,
  }),
  getTutorialError: (error: unknown) => ({
    type: actionTypes.GET_TUTORIAL_FINISH,
    payload: {error},
  }),

  addTutorial: (data: any) => ({
    type: actionTypes.ADD_TUTORIAL_START,
    payload: data,
  }),
  addTutorialSuccess: (task: any) => ({
    type: actionTypes.ADD_TUTORIAL_SUCCESS,
    payload: task,
  }),
  addTutorialFinish: () => ({
    type: actionTypes.ADD_TUTORIAL_FINISH,
  }),
  addTutorialReset: () => ({
    type: actionTypes.ADD_TUTORIAL_RESET,
  }),

  updateTutorial: (data: any, id: string) => ({
    type: actionTypes.UPDATE_TUTORIAL_START,
    payload: {data, id},
  }),
  updateTutorialSuccess: (task: any) => ({
    type: actionTypes.UPDATE_TUTORIAL_SUCCESS,
    payload: task,
  }),
  updateTutorialFinish: () => ({
    type: actionTypes.UPDATE_TUTORIAL_FINISH,
  }),
  updateTutorialReset: () => ({
    type: actionTypes.UPDATE_TUTORIAL_RESET,
  }),

  deleteTutorial: (data: any) => ({
    type: actionTypes.DELETE_TUTORIAL_START,
    payload: {tutorialId: data},
  }),
  deleteTutorialSuccess: (data: any) => ({
    type: actionTypes.DELETE_TUTORIAL_SUCCESS,
    payload: data,
  }),
  deleteTutorialFinish: () => ({
    type: actionTypes.DELETE_TUTORIAL_FINISH,
  }),
  activateTutorial: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_REQUEST,
    payload: {tutorialId: id},
  }),
  deactivateTutorial: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_REQUEST,
    payload: {tutorialId: id},
  }),
  singleActivateTutorial: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_REQUEST,
    payload: {tutorialId: [id]},
  }),
  singleDeactivateTutorial: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_REQUEST,
    payload: {tutorialId: [id]},
  }),

  sortTutorial: (data: any) => ({
    type: actionTypes.SORT_TUTORIAL_START,
    payload: data,
  }),
  sortTutorialSuccess: (data: any) => ({
    type: actionTypes.SORT_TUTORIAL_SUCCESS,
    payload: data,
  }),
  sortTutorialFinish: () => ({
    type: actionTypes.SORT_TUTORIAL_FINISH,
  }),
  sortTutorialReset: () => ({
    type: actionTypes.SORT_TUTORIAL_RESET,
  }),
}
