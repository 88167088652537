import {lazy} from 'react'

import {RouteComponentProps} from 'react-router-dom'
import FunctionalArea from 'src/app/modules/careerSettings/components/FunctionalArera/components'
import Position from 'src/app/modules/careerSettings/components/Position/components'
import QUALIFICATION from 'src/app/modules/careerSettings/components/Qualification/components'
import Categories from 'src/app/modules/cms/components/categories/components'
import ContentManager from 'src/app/modules/cms/components/content/components/index'
import BankDetailComponent from '../modules/aboutBank/components/component'
import AchievementsAwardManager from '../modules/achievementsAndAwards/components'
import ApiManager from '../modules/apiManager/components'
import AppointmentType from '../modules/appointments/components/applyAppointment/components'
import AppointmentRequest from '../modules/appointments/components/appointmentRequest/components'
import AddStatus from '../modules/appointments/components/appointmentRequest/components/AddStatus'
import AppointmentReason from '../modules/appointments/components/reasonForAppointment/components'
import BenefitCalculatorManager from '../modules/benefitCalculator/components/benefitManager/components'
import BenefitCalculatorMasterDataSettings from '../modules/benefitCalculator/components/benefitMaster/components'
import BenefitCalculatorCategorySettings from '../modules/benefitCalculator/components/categorySettings/components'
import BenefitCalculatorSubCategorySettings from '../modules/benefitCalculator/components/subCategorySettings/components'
import BenefitCalculatorListManager from '../modules/benefitCalculatorListManager/components'
import CardComparisonManager from '../modules/cardComparison/components/cardComparisonManager/components'
import CardComparisonMaster from '../modules/cardComparison/components/cardComparisonMaster/components'
import CardComparisonCategorySettings from '../modules/cardComparison/components/categorySettings/components'
import CardMenu from '../modules/cardMenu/components'
import GeneralApplication from '../modules/careers/components/generalApplication/component'
import InternshipAppliedList from '../modules/careers/components/internshipAppliedList/component'
import OnboardedCandidates from '../modules/careers/components/onboardedCandidates/component'
import ExamShortlistedCandidates from '../modules/careers/components/shortlistedCandidatesForExamReport/component'
import GroupDiscussionShortlistedCandidates from '../modules/careers/components/shortlistedCandidatesForGroupDiscussion/component'
import InterviewShortlistedCandidates from '../modules/careers/components/shortlistedCandidatesForInterviewReport/component'
import Vacancy from '../modules/careers/components/vacancy/component'
import VacancyApplicantReport from '../modules/careers/components/vacancyApplicantReport/components'
import CertificateCategoryComponent from '../modules/certificate/components/certificateCategory/component'
import CertificateManagerComponent from '../modules/certificate/components/certificateManager/component'
import BankDetail from '../modules/cms/components/aboutBankDetail/components'
import applyforMembership from '../modules/cms/components/applyforMembership/components'
import Banner from '../modules/cms/components/banner/components'
import BannerOffer from '../modules/cms/components/bannerOffer/components'
import CustomerTestimonialsComponent from '../modules/cms/components/customer-testimonials/components'
import EmailTemplate from '../modules/cms/components/emailTemplate/components'
import FaqCategory from '../modules/cms/components/faqCategory/components'
import FaqManager from '../modules/cms/components/faqManager/components'
import FeaturedImage from '../modules/cms/components/featuredImage/components'
import Gallery from '../modules/cms/components/gallery/components'
import MenuManager from '../modules/cms/components/menu/components'
import Review from '../modules/cms/components/review/components'
import RoadBlockPopup from '../modules/cms/components/roadblockPopup/components'
import SuccessStories from '../modules/cms/components/successStories/components'
import TextPopup from '../modules/cms/components/textPopup/components'
import CookieManager from '../modules/cookie/components'
import CSRCategory from '../modules/csr/components/category/components'
import CSR from '../modules/csr/components/csr/components'
import SocialMediaLoginManager from '../modules/customerLoginManager/components'
import DisputeClaimCardType from '../modules/disputeClaim/components/disputeClaimCardType/components'
import DisputeClaimManager from '../modules/disputeClaim/components/disputeClaimManager/components'
import DisputeClaimOption from '../modules/disputeClaim/components/disputeClaimOption/components'

import ContentFeedbackFormComponent from '../modules/feedbacks/components/feedbackManager/component/FeedbackTable'
import DocumentCategoryComponent from '../modules/financialDocument/components/documentCategory/component'
import DocumentManagerComponent from '../modules/financialDocument/components/documentManager/component'
import DocumentSubCategoryComponent from '../modules/financialDocument/components/documentSubCategory/component'
import FormDownloadComponent from '../modules/formsAndDownload/components/formsDownload/component'
import GrievanceHandlingComponent from '../modules/grievance/components/grievanceHandling/component'
import ServiceCategoryComponent from '../modules/grievance/components/serviceCategory/component'
import ServiceSubTypeComponent from '../modules/grievance/components/serviceSubType/component'
import ServiceTypeComponent from '../modules/grievance/components/serviceType/component'
import HistoryManager from '../modules/history/components'
import EnquiryComponent from '../modules/inquiry/components/enquiryManager/component'
import EnquiryTypeComponent from '../modules/inquiry/components/enquiryType/component'
import IntroductoryManager from '../modules/introductory/components'
import ContentLeadFormComponent from '../modules/leadForm/components/contentLeadForm/component/ContentLeadTable'
import ProductLeadFormComponent from '../modules/leadForm/components/contentLeadForm/component/ProductLeadTable'
import ProgramLeadFormComponent from '../modules/leadForm/components/contentLeadForm/component/ProgramLeadTable'
import ServiceLeadFormComponent from '../modules/leadForm/components/contentLeadForm/component/ServiceLeadTable'
import LoanEligibilityPurpose from '../modules/loanEligibilityChecker/components/loanPurpose/components'
import LoanEligibilitySubCategory from '../modules/loanEligibilityChecker/components/loanSubCategory/components'
import LoanEligibilityTenure from '../modules/loanEligibilityChecker/components/loanTenure/components'
import MemberComponent from '../modules/membership/components/member/component'
import MemberSubTypeComponent from '../modules/membership/components/memberSubType/component'
import MemberTypeComponent from '../modules/membership/components/memberType/component'
import MerchantManagerComponent from '../modules/merchantAndOffers/components/merchantManager/component'
import MerchantSubCategoryComponent from '../modules/merchantAndOffers/components/merchantSubCategory/component'
import OfferComponent from '../modules/merchantAndOffers/components/offers/component'
import AgentComponent from '../modules/network/components/agent/component'
import AtmComponent from '../modules/network/components/atm/component'
import BranchComponent from '../modules/network/components/branch/component'
import BranchManagerComponent from '../modules/network/components/branchManager/component'
import BranchlessComponent from '../modules/network/components/branchless/component'
import ExperienceManager from '../modules/network/components/experience/components'
import ExtensionCounter from '../modules/network/components/extensionCounter/components'
import OperationIncharge from '../modules/network/components/operationIncharge/components'
import News from '../modules/newsAndNotices/components/news/components'
import Notices from '../modules/newsAndNotices/components/notices/components'
import SubCategory from '../modules/newsAndNotices/components/subCategory/components'
import NotificationComponent from '../modules/notification/components/notifications/component'
import ApplicationCategoryComponent from '../modules/onlineApplication/applicationCategory/components'
import ApplicationManagerComponent from '../modules/onlineApplication/applicationManager/components'
import PartnersComponent from '../modules/partnerComponent/components/partnerships/component'
import PayoutLocationComponent from '../modules/payoutLocation/components/component'
import ProductCategoryComponent from '../modules/products/components/productCategory/component'
import ProductComparisonCategoryComponent from '../modules/products/components/productComparisonCategory/component'
import ProductComparisonSubCategoryComponent from '../modules/products/components/productComparisonSubCategory/component'
import ProductManagerComponent from '../modules/products/components/productManager/component'
import ProductSubCategoryComponent from '../modules/products/components/productSubCategory/component'
import ProductTagComponent from '../modules/products/components/productTag/component'
import BaseRate from '../modules/rates/components/baseRate/components'
import ForexRates from '../modules/rates/components/forexRate/components'
import GoldRates from '../modules/rates/components/goldRate/components'
import InterestRate from '../modules/rates/components/interestRate/components'
import ApplyLoan from '../modules/reports/components/applyLoan/components'
import BankGuaranteeApplications from '../modules/reports/components/bankGuaranteeApplications/components'
import Portfolio from '../modules/reports/components/portfolio/components/index'
import ServiceCategoryOne from '../modules/services/components/serviceCategory/components'
import ServiceTag from '../modules/services/components/serviceTag/components'
import BackendSettings from '../modules/siteSettings/components/backendSettings/components'
import ContactSetting from '../modules/siteSettings/components/contactSettings/ContactSetting'
import HomepageSetting from '../modules/siteSettings/components/homepageSettings/components'
import KeySettings from '../modules/siteSettings/components/keySetting/components'
import ModuleManager from '../modules/siteSettings/components/moduleManager/components/ModuleManager'
import Settings from '../modules/siteSettings/components/settings/components'
import SmtpSettings from '../modules/siteSettings/components/smtpSetting/components'
import Share from '../modules/stock/components/share/components'
import SubscribeComponent from '../modules/subscribe/components/subscribeManager/component'
import SubscribeTypeComponent from '../modules/subscribe/components/subscribeType/component'
import TutorialCategorySettings from '../modules/tutorial/components/categorySettings/components'
import TutorialManager from '../modules/tutorial/components/tutorialManager/components'
import Departments from '../modules/userMgmt/components/departments/components'
import RoleManagementIndex from '../modules/userMgmt/components/roles/components/RoleMgmt'
import UserRoleManagementIndex from '../modules/userMgmt/components/userRoles/UserRole'
import UserManagementIndex from '../modules/userMgmt/components/users/components/users'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import ProductMidMenuManager from '../modules/products/components/productMidMenuManager/components'

import AgentManager from '../modules/agentManager/components'
import MenuButtonManager from '../modules/cms/components/menuButtonManager/components'
import ISGRevalidate from '../modules/isgRevalidate'
import ExchangeRate from '../modules/servicePartner/components/exchangeRate/components'
import ServicePartnerManagement from '../modules/servicePartner/components/servicePartnerManagement/components'

import IPGServiceComponent from '../modules/ipgServiceManager/ipgService/component'
import WebsiteSectionComponent from '../modules/websiteSectionManager/websiteSection/component'
import WebsiteServiceComponent from '../modules/websiteServiceManager/websiteService/component'

const ServiceManager = lazy(
  () => import('../modules/services/components/serviceManager/components')
)

export interface RouteModel {
  path?: any
  component?: React.ComponentType<RouteComponentProps>
  exact?: boolean
}

export const RouteList = [
  {
    path: '/agent/list',
    component: AgentManager,
  },
  {
    path: '/cms/isg-revalidate',
    component: ISGRevalidate,
  },
  {
    path: '/csr/csr-manager',
    component: CSR,
  },
  {
    path: '/card-comparison/card-menu',
    component: CardMenu,
  },
  {
    path: '/stock/share',
    component: Share,
  },
  {
    path: '/csr/csr-category',
    component: CSRCategory,
  },
  {
    path: '/history/manager',
    component: HistoryManager,
  },

  {
    path: '/network/experience-manager',
    component: ExperienceManager,
    exact: true,
  },
  {
    path: '/cookie/manager',
    component: CookieManager,
    exact: true,
  },
  {
    path: '/introductory/manager',
    component: IntroductoryManager,
    exact: true,
  },
  {
    path: '/benefit-calculator/manager',
    component: BenefitCalculatorManager,
    exact: true,
  },
  {
    path: '/benefit-calculator/master',
    component: BenefitCalculatorMasterDataSettings,
    exact: true,
  },
  {
    path: '/benefit-calculator/category',
    component: BenefitCalculatorCategorySettings,
    exact: true,
  },
  {
    path: '/benefit-calculator/sub-category',
    component: BenefitCalculatorSubCategorySettings,
    exact: true,
  },
  {
    path: '/loan-eligibility/tenure',
    component: LoanEligibilityTenure,
    exact: true,
  },
  {
    path: '/loan-eligibility/sub-category',
    component: LoanEligibilitySubCategory,
    exact: true,
  },
  {
    path: '/loan-eligibility/purpose',
    component: LoanEligibilityPurpose,
    exact: true,
  },
  {
    path: '/customerlogin/details',
    component: SocialMediaLoginManager,
    exact: true,
  },
  {
    path: '/benefit-calculator-list-manager',
    component: BenefitCalculatorListManager,
    exact: true,
  },
  {
    path: '/dispute/dispute-claim-card-type',
    component: DisputeClaimCardType,
    exact: true,
  },
  {
    path: '/dispute/dispute-claim-option',
    component: DisputeClaimOption,
    exact: true,
  },
  {
    path: '/dispute/dispute-claim-manager',
    component: DisputeClaimManager,
    exact: true,
  },
  {
    path: '/report/bank-guarantee-applications',
    component: BankGuaranteeApplications,
    exact: true,
  },
  {
    path: '/tutorial/tutorial-manager',
    component: TutorialManager,
    exact: true,
  },
  {
    path: '/tutorial/tutorial-category',
    component: TutorialCategorySettings,
    exact: true,
  },
  {
    path: '/dashboard',
    component: DashboardWrapper,
    exact: true,
  },
  {
    path: '/cms/content',
    component: ContentManager,
    exact: true,
  },
  {
    path: '/cms/review',
    component: Review,
    exact: true,
  },
  {
    path: '/network/extension-counter',
    component: ExtensionCounter,
    exact: true,
  },
  {
    path: '/appointments/appointment-type',
    component: AppointmentType,
    exact: true,
  },
  {
    path: '/appointments/reason-for-appointment',
    component: AppointmentReason,
    exact: true,
  },
  {
    path: '/appointments/appointment-request',
    component: AppointmentRequest,
    exact: true,
  },
  {
    path: '/appointments/appointment-request/update/:id',
    component: AddStatus,
    exact: true,
  },
  {
    path: '/report/apply-loan',
    component: ApplyLoan,
    exact: true,
  },
  {
    path: '/report/portfolio',
    component: Portfolio,
    exact: true,
  },
  {
    path: '/service/service-category',
    component: ServiceCategoryOne,
    exact: true,
  },
  {
    path: '/service/service-tag',
    component: ServiceTag,
    exact: true,
  },
  {
    path: '/cms/menu-manager',
    component: MenuManager,
    exact: true,
  },
  {
    path: '/cms/banner',
    component: Banner,
    exact: true,
  },
  {
    path: '/cms/banner-offer',
    component: BannerOffer,
    exact: true,
  },
  // {
  //   path: '/cms/csr-cms',
  //   component: CsrCMS,
  //   exact: true,
  // },
  {
    path: '/cms/success-stories',
    component: SuccessStories,
    exact: true,
  },
  {
    path: '/popup/text-popup',
    component: TextPopup,
    exact: true,
  },
  {
    path: '/career-setting/position',
    component: Position,
    exact: true,
  },
  {
    path: '/career/vacancy',
    component: Vacancy,
    exact: true,
  },

  {
    path: '/career/general-application',
    component: GeneralApplication,
    exact: true,
  },

  {
    path: '/career/vacancy-applicant-report',
    component: VacancyApplicantReport,
    exact: true,
  },
  {
    path: '/career/onboarded-candidates',
    component: OnboardedCandidates,
    exact: true,
  },
  {
    path: '/career/shortlisted-exam-candidates',
    component: ExamShortlistedCandidates,
    exact: true,
  },
  {
    path: '/career/shortlisted-interview-candidates',
    component: InterviewShortlistedCandidates,
    exact: true,
  },
  {
    path: '/career/shortlisted-group-discussion-candidates',
    component: GroupDiscussionShortlistedCandidates,
    exact: true,
  },
  {
    path: '/career/internship',
    component: InternshipAppliedList,
    exact: true,
  },
  {
    path: '/popup/road-block-popup',
    component: RoadBlockPopup,
    exact: true,
  },
  {
    path: '/cms/manage-faqs',
    component: FaqCategory,
    exact: true,
  },
  {
    path: '/cms/update-faqs/:id',
    component: FaqManager,
    exact: true,
  },
  {
    path: '/cms/email-template',
    component: EmailTemplate,
    exact: true,
  },
  {
    path: '/dashbord/cms/gallery',
    component: Gallery,
    exact: true,
  },
  {
    path: '/cms/featured-image',
    component: FeaturedImage,
    exact: true,
  },

  {
    path: '/dashbord/cms/bank-detail',
    component: BankDetail,
    exact: true,
  },
  {
    path: '/rates/interest-rate',
    component: InterestRate,
    exact: true,
  },
  {
    path: '/rates/base-rate',
    component: BaseRate,
    exact: true,
  },
  {
    path: '/cms/customer-testimonials',
    component: CustomerTestimonialsComponent,
    exact: true,
  },
  {
    path: '/usermanagement/users',
    component: UserManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/user-role',
    component: UserRoleManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/role',
    component: RoleManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/departments',
    component: Departments,
    exact: true,
  },
  {
    path: '/sitesetting/module',
    component: ModuleManager,
    exact: true,
  },
  {
    path: '/sitesetting/contact-setting',
    component: ContactSetting,
    exact: true,
  },
  {
    path: '/sitesetting/homepage-setting',
    component: HomepageSetting,
    exact: true,
  },
  {
    path: '/sitesetting/backend-setting',
    component: BackendSettings,
    exact: true,
  },
  {
    path: '/sitesetting/setting',
    component: Settings,
    exact: true,
  },
  {
    path: '/sitesetting/smtp-setting',
    component: SmtpSettings,
    exact: true,
  },
  {
    path: '/sitesetting/key-setting',
    component: KeySettings,
    exact: true,
  },

  {
    path: '/rates/gold-rates',
    component: GoldRates,
    exact: true,
  },
  {
    path: '/rates/forex-rates',
    component: ForexRates,
    exact: true,
  },
  {
    path: '/service-partner/service-partner-management',
    component: ServicePartnerManagement,
    exact: true,
  },
  {
    path: '/service-partner/exchange-rate',
    component: ExchangeRate,
    exact: true,
  },
  {
    path: '/network/branch',
    component: BranchComponent,
    exact: true,
  },
  {
    path: '/network/agent',
    component: AgentComponent,
    exact: true,
  },
  {
    path: '/network/branch-manager',
    component: BranchManagerComponent,
    exact: true,
  },
  {
    path: '/network/atm',
    component: AtmComponent,
    exact: true,
  },
  {
    path: '/network/branchless-banking',
    component: BranchlessComponent,
    exact: true,
  },
  {
    path: '/network/operation-incharge',
    component: OperationIncharge,
    exact: true,
  },
  {
    path: '/news-and-notices/news',
    component: News,
    exact: true,
  },
  {
    path: '/news-and-notices/notices',
    component: Notices,
    exact: true,
  },

  {
    path: '/news-and-notices/sub-category',
    component: SubCategory,
    exact: true,
  },
  {
    path: '/cms/categories',
    component: Categories,
    exact: true,
  },
  {
    path: '/api-manager/list',
    component: ApiManager,
    exact: true,
  },

  //MemberShip Module
  {
    path: '/membership/member-type',
    component: MemberTypeComponent,
    exact: true,
  },
  {
    path: '/membership/member-sub-type',
    component: MemberSubTypeComponent,
    exact: true,
  },
  {
    path: '/membership/member',
    component: MemberComponent,
    exact: true,
  },
  //Certificate Module
  {
    path: '/certificate/certificate-category',
    component: CertificateCategoryComponent,
    exact: true,
  },
  {
    path: '/certificate/certificate-manager',
    component: CertificateManagerComponent,
    exact: true,
  },
  //Subscribe Module
  {
    path: '/subscribe/subscribe-type',
    component: SubscribeTypeComponent,
    exact: true,
  },
  {
    path: '/subscribe/subscribe-manager',
    component: SubscribeComponent,
    exact: true,
  },
  //ENquiry Module
  {
    path: '/inquiry/inquiry-types',
    component: EnquiryTypeComponent,
    exact: true,
  },
  {
    path: '/inquiry/inquiry-manager',
    component: EnquiryComponent,
    exact: true,
  },

  //Grievance Module
  {
    path: '/grievance/service-category',
    component: ServiceCategoryComponent,
    exact: true,
  },
  {
    path: '/grievance/service-type',
    component: ServiceTypeComponent,
    exact: true,
  },
  {
    path: '/grievance/service-sub-type',
    component: ServiceSubTypeComponent,
    exact: true,
  },
  {
    path: '/grievance/grievance-manager',
    component: GrievanceHandlingComponent,
    exact: true,
  },

  //Document Handling
  {
    path: '/document/document-category',
    component: DocumentCategoryComponent,
    exact: true,
  },
  {
    path: '/document/document-sub-category',
    component: DocumentSubCategoryComponent,
    exact: true,
  },
  {
    path: '/document/manage-document',
    component: DocumentManagerComponent,
    exact: true,
  },

  //Merchant and Offers
  {
    path: '/merchant-and-offers/merchant-sub-category',
    component: MerchantSubCategoryComponent,
    exact: true,
  },

  {
    path: '/merchant-and-offers/merchant',
    component: MerchantManagerComponent,
    exact: true,
  },
  {
    path: '/merchant-and-offers/offers',
    component: OfferComponent,
    exact: true,
  },

  //Products
  {
    path: '/products/product-category',
    component: ProductCategoryComponent,
    exact: true,
  },
  {
    path: '/products/product-sub-category',
    component: ProductSubCategoryComponent,
    exact: true,
  },
  {
    path: '/products/product-tags',
    component: ProductTagComponent,
    exact: true,
  },
  {
    path: '/products/product-comparison-category',
    component: ProductComparisonCategoryComponent,
    exact: true,
  },
  {
    path: '/products/product-comparison-sub-category',
    component: ProductComparisonSubCategoryComponent,
    exact: true,
  },
  {
    path: '/products/product-manager',
    component: ProductManagerComponent,
    exact: true,
  },
  {
    path: '/products/product-mid-menu-manager',
    component: ProductMidMenuManager,
    exact: true,
  },
  {
    path: '/service/service-category',
    component: ServiceCategoryOne,
    exact: true,
  },
  {
    path: '/service/service-tag',
    component: ServiceTag,
    exact: true,
  },
  {
    path: '/service/service-manager',
    component: ServiceManager,
    exact: true,
  },
  {
    path: '/cms/apply-for-membership',
    component: applyforMembership,
    exact: true,
  },
  {
    path: '/cms/forms-and-download',
    component: FormDownloadComponent,
    exact: true,
  },
  {
    path: '/career-setting/qualification',
    component: QUALIFICATION,
    exact: true,
  },
  {
    path: '/career-setting/functional-area',
    component: FunctionalArea,
    exact: true,
  },

  //Content Lead Form
  {
    path: '/lead-form/content',
    component: ContentLeadFormComponent,
    exact: true,
  },
  {
    path: '/lead-form/product',
    component: ProductLeadFormComponent,
    exact: true,
  },
  {
    path: '/lead-form/services',
    component: ServiceLeadFormComponent,
    exact: true,
  },
  {
    path: '/lead-form/program',
    component: ProgramLeadFormComponent,
    exact: true,
  },

  //Bank Detail

  {
    path: '/sitesetting/bank-detail',
    component: BankDetailComponent,
    exact: true,
  },

  //Notification

  {
    path: '/cms/notification',
    component: NotificationComponent,
    exact: true,
  },

  //Partners
  {
    path: '/network/partners',
    component: PartnersComponent,
    exact: true,
  },

  //payout-location
  {
    path: '/network/payout-location',
    component: PayoutLocationComponent,
    exact: true,
  },
  {
    path: '/online-application/application-manager',
    component: ApplicationManagerComponent,
    exact: true,
  },
  {
    path: '/online-application/application-category',
    component: ApplicationCategoryComponent,
    exact: true,
  },

  //Achievements And Reward
  {
    path: '/achievements-award/achievements-award-manager',
    component: AchievementsAwardManager,
    exact: true,
  },
  // Card Comparison
  {
    path: '/card-comparison/manager',
    component: CardComparisonManager,
    exact: true,
  },
  {
    path: '/card-comparison/master',
    component: CardComparisonMaster,
    exact: true,
  },
  {
    path: '/card-comparison/category',
    component: CardComparisonCategorySettings,
    exact: true,
  },

  //Menu Button Manager
  {
    path: '/cms/menu-button-manager',
    component: MenuButtonManager,
    exact: true,
  },
  //feedback
  {
    path: '/feedback/feedback-manager',
    component: ContentFeedbackFormComponent,
    exact: true,
  },
  // IPG Service
  {
    path: '/ipg-service-manager/ipg-service',
    component: IPGServiceComponent,
    exact: true,
  },
  // Website section
  {
    path: '/website-section-manager/website-section',
    component: WebsiteSectionComponent,
    exact: true,
  },
  // website service
  {
    path: '/website-service-manager/website-service',
    component: WebsiteServiceComponent,
    exact: true,
  },
]
