import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {IPGServiceModel, SortSearchActionModel, SortIPGServiceModel} from '../Model/IPGServiceModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getIPGServiceSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getIPGService, params)
    yield put(actions.getIPGServiceSuccess(response?.data?.data))
    yield put(actions.getIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getIPGServiceFinish())
  }
}

function* getAllIPGServiceSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllIPGService)
    yield put(actions.getAllIPGServiceSuccess(response?.data?.data))
    yield put(actions.getAllIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllIPGServiceFinish())
  }
}

function* addIPGServiceSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addIPGService, body)
    yield put(actions.addIPGServiceSuccess(response.data?.data))
    yield put(actions.addIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addIPGServiceFinish())
  }
}

function* enableIPGServiceSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableIPGService, selectedUsers)
    yield put(actions.enableIPGServiceSuccess(response?.data))
    yield put(actions.enableIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableIPGServiceFinish())
  }
}

function* disableIPGServiceSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableIPGService, selectedUsers)
    yield put(actions.disableIPGServiceSuccess(response?.data))
    yield put(actions.disableIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableIPGServiceFinish())
  }
}

function* singleEnableIPGServiceSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableIPGService, selectedUsers)
    yield put(actions.singleEnableIPGServiceSuccess(response?.data))
    yield put(actions.singleEnableIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableIPGServiceFinish())
  }
}

function* singleDisableIPGServiceSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableIPGService, selectedUsers)
    yield put(actions.singleDisableIPGServiceSuccess(response?.data))
    yield put(actions.singleDisableIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableIPGServiceFinish())
  }
}

function* updateIPGServiceSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.updateIPGService, body, action.payload?.id)
    yield put(actions.updateIPGServiceSuccess(response.data?.data))
    yield put(actions.updateIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateIPGServiceFinish())
  }
}

function* deleteIPGServiceSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteIPGService, body)
    yield put(actions.deleteIPGServiceSuccess(response.data?.data))
    yield put(actions.deleteIPGServiceFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteIPGServiceFinish())
  }
}

function* sortIPGService(action: SortSearchActionModel) {
  try {
    const body: SortIPGServiceModel = action.payload
    const response: ResponseModel = yield call(service.sortIPGService, body)

    yield put({
      type: actionTypes.SORT_IPG_SERVICE_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_IPG_SERVICE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_IPG_SERVICE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_IPG_SERVICE_START, getIPGServiceSaga)
  yield takeLatest(actionTypes.GET_ALL_IPG_SERVICE_START, getAllIPGServiceSaga)
  yield takeLatest(actionTypes.ADD_IPG_SERVICE_START, addIPGServiceSaga)
  yield takeLatest(actionTypes.UPDATE_IPG_SERVICE_START, updateIPGServiceSaga)
  yield takeLatest(actionTypes.DELETE_IPG_SERVICE_START, deleteIPGServiceSaga)
  yield takeLatest(actionTypes.ENABLE_IPG_SERVICE_REQUEST, enableIPGServiceSaga)
  yield takeLatest(actionTypes.DISABLE_IPG_SERVICE_REQUEST, disableIPGServiceSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_IPG_SERVICE_REQUEST, singleEnableIPGServiceSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_IPG_SERVICE_REQUEST, singleDisableIPGServiceSaga)
  yield takeLatest(actionTypes.SORT_IPG_SERVICE_START, sortIPGService)
}
