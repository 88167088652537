import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {LOGOUT_MODEL} from 'src/cms/partials'
import * as auth from '../../app/modules/auth/redux/actions'
export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = '*/*'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken, guestToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      } else if (guestToken) {
        config.headers.Authorization = `Bearer ${guestToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      // console.log(error)
      // console.log('Response:', error.response)
      // console.log('Response:', error.response.status)
      if (
        error.response &&
        (error.response.status === 401 ||
          error.response.data.message === 'Unauthorized' ||
          error.response.data.code === '401')
      ) {
        // const dispatch = useDispatch()

        store.dispatch({type: 'AUTH_LOGOUT'})
        // console.log('Response:', error.response)

        setTimeout(() => {
          // window.dispatchEvent(new CustomEvent('PERMISSION_CHANGED', {detail: 'SIGN_IN'}))
          // console.log('Response:', error.response.status)
          window.location.reload()

          // axios
          //   .get(LOGOUT_MODEL)
          //   .then((response: any) => {
          //     dispatch(auth.actions.logout())
          //     toast.success('Logout successfully')
          //   })
          //   .catch((error: any) => {
          //     console.error(error)
          //   })
        }, 1000)
      }

      return Promise.reject(error)
    }
  )
}
