import {FieldArray, FormikErrors} from 'formik'
import {cloneDeep, isEmpty} from 'lodash'
import {ChangeEvent, useEffect} from 'react'
import {FormOptionModal} from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{[key: string]: string}> | any
  touched: any
  values: {[key: string]: string} | any
  editSelectedData: {[key: string]: string} | any
  relatedOptions: FormOptionModal[]
  managerOptions: FormOptionModal[]
  validationState: any
  setValidationState: any
  optionName: string
  optionTitle: string
  relatedOptionIdName: string
  setFieldValue: any
}

export function RelatedComponent({
  handleChange,
  errors,
  touched,
  values,
  editSelectedData,
  relatedOptions,
  managerOptions,
  validationState,
  setValidationState,
  optionName,
  optionTitle,
  relatedOptionIdName,
  setFieldValue,
}: Props) {
  const showRelatedId = relatedOptions.find(
    (item: FormOptionModal) => item.value === values[`${optionName}`]
  )

  useEffect(() => {
    if (
      !isEmpty(showRelatedId) &&
      showRelatedId?.systemName === 'yes' &&
      !isEmpty(values[`${optionName}`])
    ) {
      if (optionName === 'productRelatedId') {
        setValidationState({
          ...validationState,
          relatedData: Yup.array().of(
            Yup.object().shape({
              productId: Yup.string().required('Product is required'),
              text: Yup.string().required('Text is required'),
              subText: Yup.string().required('Sub text is required'),
            })
          ),
        })
      } else if (optionName === 'serviceRelatedId') {
        setValidationState({
          ...validationState,
          relatedData: Yup.array().of(
            Yup.object().shape({
              serviceId: Yup.string().required('Service is required'),
              text: Yup.string().required('Text is required'),
              subText: Yup.string().required('Sub text is required'),
            })
          ),
        })
      }
    } else if (!isEmpty(showRelatedId) && showRelatedId?.systemName === 'no') {
      let newValidationSchema = cloneDeep(validationState)
      !isEmpty(newValidationSchema['relatedData']) && delete newValidationSchema['relatedData']
      setValidationState(newValidationSchema)
    }
  }, [values])

  return (
    <>
      {/* {JSON.stringify(editSelectedData)} */}
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label={`Show Related ${optionTitle} Section`}
              name={optionName}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={relatedOptions}
              required
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>

          {showRelatedId?.systemName === 'yes' && (
            <div className='row'>
              <FieldArray
                name='relatedData'
                render={(arrayHelpers) => (
                  <div>
                    {values.relatedData && values.relatedData?.length > 0
                      ? values.relatedData?.map((fields: any, index: any) => {
                          return (
                            <div className='row mb-4' key={index}>
                              <div className='col-md-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Please select'
                                  label={optionName === 'productRelatedId' ? 'Product' : 'Service'}
                                  name={
                                    optionName === 'productRelatedId'
                                      ? `relatedData.${index}.productId`
                                      : `relatedData.${index}.serviceId`
                                  }
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={managerOptions}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  required
                                  arrValue={
                                    optionName === 'productRelatedId'
                                      ? !isEmpty(editSelectedData) &&
                                        !values.relatedData[index]?.productId
                                        ? editSelectedData?.relatedSetting?.productRelatedSetting[
                                            index
                                          ]?.relatedProduct?.productId
                                        : values.relatedData[index].productId
                                      : optionName === 'serviceRelatedId'
                                      ? !isEmpty(editSelectedData) &&
                                        !values.relatedData[index]?.serviceId
                                        ? editSelectedData?.relatedSetting?.serviceRelatedSetting?.[
                                            index
                                          ]?.relatedService?.serviceId
                                        : values.relatedData?.[index]?.serviceId
                                      : ''
                                  }
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter Title (EN)'
                                  name={`relatedData[${index}].text`}
                                  label='Title (EN)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={showRelatedId?.systemName === 'yes' ? true : false}
                                  // defaultValue={fields?.text}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter Title (NP)'
                                  name={`relatedData[${index}].textNp`}
                                  label='Title (NP)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // defaultValue={fields?.subText}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter SubTitle (EN)'
                                  name={`relatedData[${index}].subText`}
                                  label='SubTitle (EN)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={showRelatedId?.systemName === 'yes' ? true : false}
                                  // defaultValue={fields?.subText}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter SubTitle (NP)'
                                  name={`relatedData[${index}].subTextNp`}
                                  label='SubTitle (NP)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // defaultValue={fields?.subTextNp}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='number'
                                  placeholder='Enter order'
                                  name={`relatedData[${index}].order`}
                                  label='Order'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // defaultValue={fields?.subTextNp}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter Button Text (EN)'
                                  name={`relatedData[${index}].buttonText`}
                                  label='Button Text (EN)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // defaultValue={fields?.buttonText}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter Button Text (NP)'
                                  name={`relatedData[${index}].buttonTextNp`}
                                  label='Button Text (NP)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // defaultValue={fields?.buttonTextNp}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter Button Link'
                                  name={`relatedData[${index}].buttonLink`}
                                  label='Button Link'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // defaultValue={fields?.buttonLink}
                                />
                              </div>
                              {arrayHelpers?.form?.values?.relatedData.length > 1 && (
                                <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                  <button
                                    type='button'
                                    className='p-2 ps-5 pe-5 btn btn-secondary'
                                    onClick={() => {
                                      arrayHelpers.remove(index)
                                      if (optionName === 'serviceRelatedId') {
                                        Object.assign(
                                          editSelectedData?.relatedSetting?.serviceRelatedSetting?.[
                                            index
                                          ]?.relatedService,
                                          {serviceId: ''}
                                        )
                                      }

                                      if (optionName === 'productRelatedId') {
                                        Object.assign(
                                          editSelectedData?.relatedSetting?.productRelatedSetting?.[
                                            index
                                          ]?.relatedProduct,
                                          {productId: ''}
                                        )
                                      }
                                    }}
                                    data-cy='modal-field-remove'
                                  >
                                    Remove
                                  </button>
                                </div>
                              )}
                            </div>
                          )
                        })
                      : null}
                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                      <button
                        className='p-2 ps-5 pe-5 btn btn-primary'
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            productId: '',
                            serviceId: '',
                            text: '',
                            textNp: '',
                            subText: '',
                            subTextNp: '',
                            buttonText: '',
                            buttonTextNp: '',
                            buttonLink: '',
                            order: '',
                          })
                        }
                        data-cy='modal-field-add'
                      >
                        + Add More
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
