export const actionTypes = {
  // product BREADCRUMB OPTION
  GET_PRODUCT_BREADCRUMB_OPTION_START: 'GET_PRODUCT_BREADCRUMB_OPTION_START',
  GET_PRODUCT_BREADCRUMB_OPTION_SUCCESS: 'GET_PRODUCT_BREADCRUMB_OPTION_SUCCESS',
  GET_PRODUCT_BREADCRUMB_OPTION_FINISH: 'GET_PRODUCT_BREADCRUMB_OPTION_FINISH',

  //GET APPLY NOW SECTION CONSTANTS
  GET_PRODUCT_SLIDER_SECTION_START: 'GET_PRODUCT_SLIDER_SECTION_START',
  GET_PRODUCT_SLIDER_SECTION_SUCCESS: 'GET_PRODUCT_SLIDER_SECTION_SUCCESS',
  GET_PRODUCT_SLIDER_SECTION_FINISH: 'GET_PRODUCT_SLIDER_SECTION_FINISH',

  // get
  GET_ALL_PRODUCT_MANAGER_START: 'GET_ALL_PRODUCT_MANAGER_START',
  GET_ALL_PRODUCT_MANAGER_SUCCESS: 'GET_ALL_PRODUCT_MANAGER_SUCCESS',
  GET_ALL_PRODUCT_MANAGER_FINISH: 'GET_ALL_PRODUCT_MANAGER_FINISH',

  // extended hours
  GET_PRODUCT_MANAGER_START: 'GET_PRODUCT_MANAGER_START',
  GET_PRODUCT_MANAGER_SUCCESS: 'GET_PRODUCT_MANAGER_SUCCESS',
  GET_PRODUCT_MANAGER_FINISH: 'GET_PRODUCT_MANAGER_FINISH',

  // extended by tag
  GET_PRODUCT_MANAGER_BY_TAG_START: 'GET_PRODUCT_MANAGER_BY_TAG_START',
  GET_PRODUCT_MANAGER_BY_TAG_SUCCESS: 'GET_PRODUCT_MANAGER_BY_TAG_SUCCESS',
  GET_PRODUCT_MANAGER_BY_TAG_FINISH: 'GET_PRODUCT_MANAGER_BY_TAG_FINISH',

  // product COMPARISON_STATUS
  GET_PRODUCT_COMPARISON_STATUS_START: 'GET_PRODUCT_COMPARISON_STATUS_START',
  GET_PRODUCT_COMPARISON_STATUS_SUCCESS: 'GET_PRODUCT_COMPARISON_STATUS_SUCCESS',
  GET_PRODUCT_COMPARISON_STATUS_FINISH: 'GET_PRODUCT_COMPARISON_STATUS_FINISH',

  // product BANNER OPTION
  GET_PRODUCT_BANNER_OPTION_START: 'GET_PRODUCT_BANNER_OPTION_START',
  GET_PRODUCT_BANNER_OPTION_SUCCESS: 'GET_PRODUCT_BANNER_OPTION_SUCCESS',
  GET_PRODUCT_BANNER_OPTION_FINISH: 'GET_PRODUCT_BANNER_OPTION_FINISH',

  // product popularity
  GET_PRODUCT_POPULARITY_START: 'GET_PRODUCT_POPULARITY_START',
  GET_PRODUCT_POPULARITY_SUCCESS: 'GET_PRODUCT_POPULARITY_SUCCESS',
  GET_PRODUCT_POPULARITY_FINISH: 'GET_PRODUCT_POPULARITY_FINISH',

  // product APPLY_NOW_OPTION
  GET_PRODUCT_APPLY_NOW_OPTION_START: 'GET_PRODUCT_APPLY_NOW_OPTION_START',
  GET_PRODUCT_APPLY_NOW_OPTION_SUCCESS: 'GET_PRODUCT_APPLY_NOW_OPTION_SUCCESS',
  GET_PRODUCT_APPLY_NOW_OPTION_FINISH: 'GET_PRODUCT_APPLY_NOW_OPTION_FINISH',

  // product COMPETITOR_STATUS
  GET_PRODUCT_COMPETITOR_STATUS_START: 'GET_PRODUCT_COMPETITOR_STATUS_START',
  GET_PRODUCT_COMPETITOR_STATUS_SUCCESS: 'GET_PRODUCT_COMPETITOR_STATUS_SUCCESS',
  GET_PRODUCT_COMPETITOR_STATUS_FINISH: 'GET_PRODUCT_COMPETITOR_STATUS_FINISH',

  // product DOCUMENT_OPTION
  GET_PRODUCT_DOCUMENT_OPTION_START: 'GET_PRODUCT_DOCUMENT_OPTION_START',
  GET_PRODUCT_DOCUMENT_OPTION_SUCCESS: 'GET_PRODUCT_DOCUMENT_OPTION_SUCCESS',
  GET_PRODUCT_DOCUMENT_OPTION_FINISH: 'GET_PRODUCT_DOCUMENT_OPTION_FINISH',

  // product FAQ_OPTION
  GET_PRODUCT_FAQ_OPTION_START: 'GET_PRODUCT_FAQ_OPTION_START',
  GET_PRODUCT_FAQ_OPTION_SUCCESS: 'GET_PRODUCT_FAQ_OPTION_SUCCESS',
  GET_PRODUCT_FAQ_OPTION_FINISH: 'GET_PRODUCT_FAQ_OPTION_FINISH',

  // product FEATURE_OPTION
  GET_PRODUCT_FEATURE_OPTION_START: 'GET_PRODUCT_FEATURE_OPTION_START',
  GET_PRODUCT_FEATURE_OPTION_SUCCESS: 'GET_PRODUCT_FEATURE_OPTION_SUCCESS',
  GET_PRODUCT_FEATURE_OPTION_FINISH: 'GET_PRODUCT_FEATURE_OPTION_FINISH',

  // product INTEREST_RATE_OPTION
  GET_PRODUCT_INTEREST_RATE_OPTION_START: 'GET_PRODUCT_INTEREST_RATE_OPTION_START',
  GET_PRODUCT_INTEREST_RATE_OPTION_SUCCESS: 'GET_PRODUCT_INTEREST_RATE_OPTION_SUCCESS',
  GET_PRODUCT_INTEREST_RATE_OPTION_FINISH: 'GET_PRODUCT_INTEREST_RATE_OPTION_FINISH',

  // product LEAD_FORM_OPTION
  GET_PRODUCT_LEAD_FORM_OPTION_START: 'GET_PRODUCT_LEAD_FORM_OPTION_START',
  GET_PRODUCT_LEAD_FORM_OPTION_SUCCESS: 'GET_PRODUCT_LEAD_FORM_OPTION_SUCCESS',
  GET_PRODUCT_LEAD_FORM_OPTION_FINISH: 'GET_PRODUCT_LEAD_FORM_OPTION_FINISH',

  // product MEDIA_TYPE
  GET_PRODUCT_MEDIA_TYPE_START: 'GET_PRODUCT_MEDIA_TYPE_START',
  GET_PRODUCT_MEDIA_TYPE_SUCCESS: 'GET_PRODUCT_MEDIA_TYPE_SUCCESS',
  GET_PRODUCT_MEDIA_TYPE_FINISH: 'GET_PRODUCT_MEDIA_TYPE_FINISH',

  // product RELATED_OPTION
  GET_PRODUCT_RELATED_OPTION_START: 'GET_PRODUCT_RELATED_OPTION_START',
  GET_PRODUCT_RELATED_OPTION_SUCCESS: 'GET_PRODUCT_RELATED_OPTION_SUCCESS',
  GET_PRODUCT_RELATED_OPTION_FINISH: 'GET_PRODUCT_RELATED_OPTION_FINISH',

  // product REVIEW_OPTION
  GET_PRODUCT_REVIEW_OPTION_START: 'GET_PRODUCT_REVIEW_OPTION_START',
  GET_PRODUCT_REVIEW_OPTION_SUCCESS: 'GET_PRODUCT_REVIEW_OPTION_SUCCESS',
  GET_PRODUCT_REVIEW_OPTION_FINISH: 'GET_PRODUCT_REVIEW_OPTION_FINISH',

  // add product manager
  ADD_PRODUCT_MANAGER_START: 'ADD_PRODUCT_MANAGER_START',
  ADD_PRODUCT_MANAGER_SUCCESS: 'ADD_PRODUCT_MANAGER_SUCCESS',
  ADD_PRODUCT_MANAGER_FINISH: 'ADD_PRODUCT_MANAGER_FINISH',
  RESET_PRODUCT_TAG: 'RESET_PRODUCT_TAG',

  // update PRODUCT_TAG
  UPDATE_PRODUCT_MANAGER_START: 'UPDATE_PRODUCT_MANAGER_START',
  UPDATE_PRODUCT_MANAGER_SUCCESS: 'UPDATE_PRODUCT_MANAGER_SUCCESS',
  UPDATE_PRODUCT_MANAGER_FINISH: 'UPDATE_PRODUCT_MANAGER_FINISH',

  // delete PRODUCT_TAG
  DELETE_PRODUCT_MANAGER_START: 'DELETE_PRODUCT_MANAGER_START',
  DELETE_PRODUCT_MANAGER_SUCCESS: 'DELETE_PRODUCT_MANAGER_SUCCESS',
  DELETE_PRODUCT_MANAGER_FINISH: 'DELETE_PRODUCT_MANAGER_FINISH',

  // Enable PRODUCT_TAG
  ENABLE_PRODUCT_MANAGER_REQUEST: 'ENABLE_PRODUCT_MANAGER_REQUEST',
  ENABLE_PRODUCT_MANAGER_SUCCESS: 'ENABLE_PRODUCT_MANAGER_SUCCESS',
  ENABLE_PRODUCT_MANAGER_FINISH: 'ENABLE_PRODUCT_MANAGER_FINISH',

  // Disable PRODUCT_TAG
  DISABLE_PRODUCT_MANAGER_REQUEST: 'DISABLE_PRODUCT_MANAGER_REQUEST',
  DISABLE_PRODUCT_MANAGER_SUCCESS: 'DISABLE_PRODUCT_MANAGER_SUCCESS',
  DISABLE_PRODUCT_MANAGER_FINISH: 'DISABLE_PRODUCT_MANAGER_FINISH',

  // Enable PRODUCT_TAG
  SINGLE_ENABLE_PRODUCT_MANAGER_REQUEST: 'SINGLE_ENABLE_PRODUCT_MANAGER_REQUEST',
  SINGLE_ENABLE_PRODUCT_MANAGER_SUCCESS: 'SINGLE_ENABLE_PRODUCT_MANAGER_SUCCESS',
  SINGLE_ENABLE_PRODUCT_MANAGER_FINISH: 'SINGLE_ENABLE_PRODUCT_MANAGER_FINISH',

  // Disable PRODUCT_TAG
  SINGLE_DISABLE_PRODUCT_MANAGER_REQUEST: 'SINGLE_DISABLE_PRODUCT_MANAGER_REQUEST',
  SINGLE_DISABLE_PRODUCT_MANAGER_SUCCESS: 'SINGLE_DISABLE_PRODUCT_MANAGER_SUCCESS',
  SINGLE_DISABLE_PRODUCT_MANAGER_FINISH: 'SINGLE_DISABLE_PRODUCT_MANAGER_FINISH',

  // sort
  SORT_PRODUCT_MANAGER_START: 'SORT_PRODUCT_MANAGER_START',
  SORT_PRODUCT_MANAGER_SUCCESS: 'SORT_PRODUCT_MANAGER_SUCCESS',
  SORT_PRODUCT_MANAGER_FINISH: 'SORT_PRODUCT_MANAGER_FINISH',
  SORT_PRODUCT_MANAGER_RESET: 'SORT_PRODUCT_MANAGER_RESET',

  // product BENEFIT_OPTION
  GET_PRODUCT_BENEFIT_OPTION_START: 'GET_PRODUCT_BENEFIT_OPTION_START',
  GET_PRODUCT_BENEFIT_OPTION_SUCCESS: 'GET_PRODUCT_BENEFIT_OPTION_SUCCESS',
  GET_PRODUCT_BENEFIT_OPTION_FINISH: 'GET_PRODUCT_BENEFIT_OPTION_FINISH',

  // product TUTORIAL_OPTION
  GET_PRODUCT_TUTORIAL_OPTION_START: 'GET_PRODUCT_TUTORIAL_OPTION_START',
  GET_PRODUCT_TUTORIAL_OPTION_SUCCESS: 'GET_PRODUCT_TUTORIAL_OPTION_SUCCESS',
  GET_PRODUCT_TUTORIAL_OPTION_FINISH: 'GET_PRODUCT_TUTORIAL_OPTION_FINISH',
}
