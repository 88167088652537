import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'

import {isEmpty} from 'lodash'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import * as menuButton from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  // topMenuId: Yup.string().required('Top Menu is required'),
  category: Yup.string().required('Category is required').nullable(),
  blocks: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Title is required').nullable(),
    })
  ),
  status: Yup.string().required('Status is required'),
})

const AddMenuButtonManager = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()

  const [imagePath, setImagePath] = useState<any>('')

  const {loading, success} = useSelector((state: any) => state.menuButton)

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  useEffect(() => {
    if (success) {
      dispatch(menuButton?.actions.getMenuButton(params))
      isEmpty(editSelectedData)
        ? toast.success('Menu Button added successfully')
        : toast.success('Menu Button edited successfully')
      dispatch(menuButton?.actions?.addMenuButtonReset())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Menu Button</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                topMenuId: '',
                category: '',
                categoryNp: '',
                blocks: [
                  {
                    title: '',
                    title_np: '',
                    image: '',
                    url: '',
                  },
                ],
                buttonOneTitle: '',
                buttonOneTitleNp: '',
                buttonOneLink: '',
                buttonTwoTitle: '',
                buttonTwoTitleNp: '',
                buttonTwoLink: '',
                status: 'Active',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  topMenuId: null,
                  blocks: values?.blocks?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(menuButton.actions.updateMenuButton(formData, editSelectedData?.id))
                } else {
                  dispatch(menuButton.actions.addMenuButton(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'topMenuId',
                      'category',
                      'categoryNp',
                      'blocks',
                      'status',
                      'buttonOneTitle',
                      'buttonOneTitleNp',
                      'buttonOneLink',
                      'buttonTwoTitle',
                      'buttonTwoTitleNp',
                      'buttonTwoLink',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        {/* <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select product'
                            label='Top Menu'
                            name='topMenuId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={topMenuOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div> */}
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Category (EN)'
                            name='category'
                            label='Category (EN)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            required={true}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Category (NP)'
                            name='categoryNp'
                            label='Category (NP)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            required={false}
                            touched={touched}
                          />
                        </div>
                        {/* Mid Menu Section */}
                        <div className='row pt-5 border-top'>
                          <h5>Menu Button Section</h5>
                          <div className='row'>
                            <FieldArray
                              name='blocks'
                              render={(arrayHelpers) => (
                                <div>
                                  {values?.blocks && values?.blocks?.length > 0
                                    ? values?.blocks?.map((friend: any, index: any) => (
                                        <div className='row' key={index}>
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              placeholder='Enter Title (EN)'
                                              name={`blocks.${index}.title`}
                                              label='Title (EN)'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              required={true}
                                              touched={touched}
                                            />
                                          </div>

                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              placeholder='Enter Title (NP)'
                                              name={`blocks.${index}.title_np`}
                                              label='Title (NP)'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>

                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              placeholder='Enter Link'
                                              name={`blocks.${index}.url`}
                                              label='Link'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>

                                          {/* media section */}
                                          {/* <div className='col-md-6 col-xs-12 mb-2'>
                                            <FormInputMediaManager
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              label='Upload Icon'
                                              name={`blocks.${index}.image`}
                                              setFieldValue={setFieldValue}
                                              setImageUrl={setImagePath}
                                              value={values?.blocks[index]?.image}
                                            />
                                            {!isEmpty(values?.blocks[index]?.image) ? (
                                              <>
                                                <li className='listing'>
                                                  <div className='thumbImageBlock'>
                                                    <button
                                                      type='button'
                                                      title='Remove'
                                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                      onClick={() => {
                                                        setImagePath('')
                                                        setFieldValue(`blocks.${index}.image`, '')
                                                      }}
                                                      data-cy={`modal-thumbnail-remove-${index}`}
                                                    >
                                                      X
                                                    </button>

                                                    <img
                                                      className='thumbImage w-100 h-100'
                                                      src={`${imageBaseUrl}/${values?.blocks[index]?.image}`}
                                                      alt=''
                                                    />
                                                  </div>
                                                </li>
                                              </>
                                            ) : null}
                                          </div>
                                          */}
                                          {index > 0 && (
                                            <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                              <button
                                                type='button'
                                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                                onClick={() => arrayHelpers.remove(index)}
                                                data-cy={`modal-field-remove-${index}`}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    : null}
                                  {values?.blocks?.length == 12 ? null : (
                                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                      <button
                                        className='p-2 ps-5 pe-5 mt-2 btn btn-primary'
                                        type='button'
                                        onClick={() =>
                                          arrayHelpers.push({
                                            id: null,
                                            title: '',
                                            title_np: '',
                                            url: '',
                                            image: '',
                                          })
                                        }
                                        data-cy='modal-field-add'
                                      >
                                        + Add More
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        {/* Extra Section  */}

                        {/* <div className='row pt-5 border-top'>
                          <h2>Extra Section</h2>
                          <div className='row'>
                            <div className='row'>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='First Button Title (EN)'
                                  name='buttonOneTitle'
                                  label='First Button Title (EN)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='First Button Title (NP)'
                                  name='buttonOneTitleNp'
                                  label='First Button Title (NP)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='First Button Link'
                                  name='buttonOneLink'
                                  label='First Button Link'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Second Button Title (EN)'
                                  name='buttonTwoTitle'
                                  label='Second Button Title (EN)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Second Button Title (NP)'
                                  name='buttonTwoTitleNp'
                                  label='Second Button Title (NP)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Second Button Link'
                                  name='buttonTwoLink'
                                  label='Second Button Link'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                         */}
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddMenuButtonManager
