import {ParamsModel} from 'src/app/modules/common/Model'
import {IPGServiceModel, SortIPGServiceModel} from '../Model/IPGServiceModel'
import {actionTypes} from './constants'
export const actions = {
  // get IPGService
  getAllIPGService: () => ({
    type: actionTypes.GET_ALL_IPG_SERVICE_START,
  }),
  getAllIPGServiceSuccess: (data: IPGServiceModel | any) => ({
    type: actionTypes.GET_ALL_IPG_SERVICE_SUCCESS,
    payload: data,
  }),
  getAllIPGServiceFinish: () => ({
    type: actionTypes.GET_ALL_IPG_SERVICE_FINISH,
  }),

  // get IPGService DATA
  getIPGService: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_IPG_SERVICE_START,
    payload: params,
  }),
  getIPGServiceSuccess: (data: IPGServiceModel | any) => ({
    type: actionTypes.GET_IPG_SERVICE_SUCCESS,
    payload: data,
  }),
  getIPGServiceFinish: () => ({
    type: actionTypes.GET_IPG_SERVICE_FINISH,
  }),

  // create key
  addIPGService: (data: IPGServiceModel | any) => ({
    type: actionTypes.ADD_IPG_SERVICE_START,
    payload: data,
  }),
  addIPGServiceSuccess: (task: any) => ({
    type: actionTypes.ADD_IPG_SERVICE_SUCCESS,
    payload: task,
  }),
  addIPGServiceFinish: () => ({
    type: actionTypes.ADD_IPG_SERVICE_FINISH,
  }),
  resetIPGService: () => ({
    type: actionTypes.RESET_SEARCH_TAG,
  }),

  //Update IPGService
  updateIPGService: (data: IPGServiceModel | any, id: string) => ({
    type: actionTypes.UPDATE_IPG_SERVICE_START,
    payload: data,
    id,
  }),

  updateIPGServiceSuccess: (data: IPGServiceModel | any) => ({
    type: actionTypes.UPDATE_IPG_SERVICE_SUCCESS,
    payload: data,
  }),

  updateIPGServiceFinish: () => ({
    type: actionTypes.UPDATE_IPG_SERVICE_FINISH,
  }),

  // delete key
  deleteIPGService: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_IPG_SERVICE_START,
    payload: {ipgServiceManagerId: data},
  }),
  deleteIPGServiceSuccess: (data: any) => ({
    type: actionTypes.DELETE_IPG_SERVICE_SUCCESS,
    payload: data,
  }),
  deleteIPGServiceFinish: () => ({
    type: actionTypes.DELETE_IPG_SERVICE_FINISH,
  }),

  //Enable IPGService
  enableIPGService: (data: any) => ({
    type: actionTypes.ENABLE_IPG_SERVICE_REQUEST,
    payload: {data},
  }),

  enableIPGServiceSuccess: (task: any) => ({
    type: actionTypes.ENABLE_IPG_SERVICE_SUCCESS,
    payload: task,
  }),
  enableIPGServiceFinish: () => ({
    type: actionTypes.ENABLE_IPG_SERVICE_FINISH,
  }),

  //Disable IPGService
  disableIPGService: (data: any) => ({
    type: actionTypes.DISABLE_IPG_SERVICE_REQUEST,
    payload: {data},
  }),

  disableIPGServiceSuccess: (task: any) => ({
    type: actionTypes.DISABLE_IPG_SERVICE_SUCCESS,
    payload: task,
  }),
  disableIPGServiceFinish: () => ({
    type: actionTypes.DISABLE_IPG_SERVICE_FINISH,
  }),

  //Enable IPGService
  singleEnableIPGService: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_IPG_SERVICE_REQUEST,
    payload: {data},
  }),

  singleEnableIPGServiceSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_IPG_SERVICE_SUCCESS,
    payload: task,
  }),
  singleEnableIPGServiceFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_IPG_SERVICE_FINISH,
  }),

  //Disable IPGService
  singleDisableIPGService: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_IPG_SERVICE_REQUEST,
    payload: {data},
  }),

  singleDisableIPGServiceSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_IPG_SERVICE_SUCCESS,
    payload: task,
  }),
  singleDisableIPGServiceFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_IPG_SERVICE_FINISH,
  }),

  // sort
  sortIPGService: (data: SortIPGServiceModel) => ({
    type: actionTypes.SORT_IPG_SERVICE_START,
    payload: data,
  }),
  sortIPGServiceSuccess: (data: Array<IPGServiceModel>) => ({
    type: actionTypes.SORT_IPG_SERVICE_SUCCESS,
    payload: data,
  }),
  sortIPGServiceFinish: () => ({
    type: actionTypes.SORT_IPG_SERVICE_FINISH,
  }),
  sortIPGServiceReset: () => ({
    type: actionTypes.SORT_IPG_SERVICE_RESET,
  }),
}
