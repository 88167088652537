import {ParamsModel} from 'src/app/modules/common/Model'
import {SortWebsiteServiceModel, WebsiteServiceModel} from '../Model/WebsiteServiceModel'
import {actionTypes} from './constants'
export const actions = {
  // get WebsiteService
  getAllWebsiteService: () => ({
    type: actionTypes.GET_ALL_WEBSITE_SERVICE_START,
  }),
  getAllWebsiteServiceSuccess: (data: WebsiteServiceModel | any) => ({
    type: actionTypes.GET_ALL_WEBSITE_SERVICE_SUCCESS,
    payload: data,
  }),
  getAllWebsiteServiceFinish: () => ({
    type: actionTypes.GET_ALL_WEBSITE_SERVICE_FINISH,
  }),

  // get WebsiteService DATA
  getWebsiteService: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_WEBSITE_SERVICE_START,
    payload: params,
  }),
  getWebsiteServiceSuccess: (data: WebsiteServiceModel | any) => ({
    type: actionTypes.GET_WEBSITE_SERVICE_SUCCESS,
    payload: data,
  }),
  getWebsiteServiceFinish: () => ({
    type: actionTypes.GET_WEBSITE_SERVICE_FINISH,
  }),

  // create key
  addWebsiteService: (data: WebsiteServiceModel | any) => ({
    type: actionTypes.ADD_WEBSITE_SERVICE_START,
    payload: data,
  }),
  addWebsiteServiceSuccess: (task: any) => ({
    type: actionTypes.ADD_WEBSITE_SERVICE_SUCCESS,
    payload: task,
  }),
  addWebsiteServiceFinish: () => ({
    type: actionTypes.ADD_WEBSITE_SERVICE_FINISH,
  }),
  resetWebsiteService: () => ({
    type: actionTypes.RESET_WEBSITE_SERVICE,
  }),

  //Update WebsiteService
  updateWebsiteService: (data: WebsiteServiceModel | any, id: string) => ({
    type: actionTypes.UPDATE_WEBSITE_SERVICE_START,
    payload: data,
    id,
  }),

  updateWebsiteServiceSuccess: (data: WebsiteServiceModel | any) => ({
    type: actionTypes.UPDATE_WEBSITE_SERVICE_SUCCESS,
    payload: data,
  }),

  updateWebsiteServiceFinish: () => ({
    type: actionTypes.UPDATE_WEBSITE_SERVICE_FINISH,
  }),

  // delete key
  deleteWebsiteService: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_WEBSITE_SERVICE_START,
    payload: {websiteServiceId: data},
  }),
  deleteWebsiteServiceSuccess: (data: any) => ({
    type: actionTypes.DELETE_WEBSITE_SERVICE_SUCCESS,
    payload: data,
  }),
  deleteWebsiteServiceFinish: () => ({
    type: actionTypes.DELETE_WEBSITE_SERVICE_FINISH,
  }),

  //Enable WebsiteService
  enableWebsiteService: (data: any) => ({
    type: actionTypes.ENABLE_WEBSITE_SERVICE_REQUEST,
    payload: {data},
  }),

  enableWebsiteServiceSuccess: (task: any) => ({
    type: actionTypes.ENABLE_WEBSITE_SERVICE_SUCCESS,
    payload: task,
  }),
  enableWebsiteServiceFinish: () => ({
    type: actionTypes.ENABLE_WEBSITE_SERVICE_FINISH,
  }),

  //Disable WebsiteService
  disableWebsiteService: (data: any) => ({
    type: actionTypes.DISABLE_WEBSITE_SERVICE_REQUEST,
    payload: {data},
  }),

  disableWebsiteServiceSuccess: (task: any) => ({
    type: actionTypes.DISABLE_WEBSITE_SERVICE_SUCCESS,
    payload: task,
  }),
  disableWebsiteServiceFinish: () => ({
    type: actionTypes.DISABLE_WEBSITE_SERVICE_FINISH,
  }),

  //Enable WebsiteService
  singleEnableWebsiteService: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_WEBSITE_SERVICE_REQUEST,
    payload: {data},
  }),

  singleEnableWebsiteServiceSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_WEBSITE_SERVICE_SUCCESS,
    payload: task,
  }),
  singleEnableWebsiteServiceFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_WEBSITE_SERVICE_FINISH,
  }),

  //Disable WebsiteService
  singleDisableWebsiteService: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_WEBSITE_SERVICE_REQUEST,
    payload: {data},
  }),

  singleDisableWebsiteServiceSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_WEBSITE_SERVICE_SUCCESS,
    payload: task,
  }),
  singleDisableWebsiteServiceFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_WEBSITE_SERVICE_FINISH,
  }),

  // sort
  sortWebsiteService: (data: SortWebsiteServiceModel) => ({
    type: actionTypes.SORT_WEBSITE_SERVICE_START,
    payload: data,
  }),
  sortWebsiteServiceSuccess: (data: Array<WebsiteServiceModel>) => ({
    type: actionTypes.SORT_WEBSITE_SERVICE_SUCCESS,
    payload: data,
  }),
  sortWebsiteServiceFinish: () => ({
    type: actionTypes.SORT_WEBSITE_SERVICE_FINISH,
  }),
  sortWebsiteServiceReset: () => ({
    type: actionTypes.SORT_WEBSITE_SERVICE_RESET,
  }),
}
