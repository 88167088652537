import {ParamsModel} from 'src/app/modules/common/Model'
import {
  WebsiteSectionModel,
  SortWebsiteSectionModel,
  WebsiteSectionBlockListModel,
} from '../Model/WebsiteSectionModel'
import {actionTypes} from './constants'
export const actions = {
  // get WebsiteSection
  getAllWebsiteSection: () => ({
    type: actionTypes.GET_ALL_WEBSITE_SECTION_START,
  }),
  getAllWebsiteSectionSuccess: (data: WebsiteSectionModel | any) => ({
    type: actionTypes.GET_ALL_WEBSITE_SECTION_SUCCESS,
    payload: data,
  }),
  getAllWebsiteSectionFinish: () => ({
    type: actionTypes.GET_ALL_WEBSITE_SECTION_FINISH,
  }),

  // get WebsiteSection DATA
  getWebsiteSection: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_WEBSITE_SECTION_START,
    payload: params,
  }),
  getWebsiteSectionSuccess: (data: WebsiteSectionModel | any) => ({
    type: actionTypes.GET_WEBSITE_SECTION_SUCCESS,
    payload: data,
  }),
  getWebsiteSectionFinish: () => ({
    type: actionTypes.GET_WEBSITE_SECTION_FINISH,
  }),

  // create key
  addWebsiteSection: (data: WebsiteSectionModel | any) => ({
    type: actionTypes.ADD_WEBSITE_SECTION_START,
    payload: data,
  }),
  addWebsiteSectionSuccess: (task: any) => ({
    type: actionTypes.ADD_WEBSITE_SECTION_SUCCESS,
    payload: task,
  }),
  addWebsiteSectionFinish: () => ({
    type: actionTypes.ADD_WEBSITE_SECTION_FINISH,
  }),
  resetWebsiteSection: () => ({
    type: actionTypes.RESET_WEBSITE_SECTION,
  }),

  //Update WebsiteSection
  updateWebsiteSection: (data: WebsiteSectionModel | any, id: string) => ({
    type: actionTypes.UPDATE_WEBSITE_SECTION_START,
    payload: data,
    id,
  }),

  updateWebsiteSectionSuccess: (data: WebsiteSectionModel | any) => ({
    type: actionTypes.UPDATE_WEBSITE_SECTION_SUCCESS,
    payload: data,
  }),

  updateWebsiteSectionFinish: () => ({
    type: actionTypes.UPDATE_WEBSITE_SECTION_FINISH,
  }),

  // delete key
  deleteWebsiteSection: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_WEBSITE_SECTION_START,
    payload: {websiteSectionId: data},
  }),
  deleteWebsiteSectionSuccess: (data: any) => ({
    type: actionTypes.DELETE_WEBSITE_SECTION_SUCCESS,
    payload: data,
  }),
  deleteWebsiteSectionFinish: () => ({
    type: actionTypes.DELETE_WEBSITE_SECTION_FINISH,
  }),

  //Enable WebsiteSection
  enableWebsiteSection: (data: any) => ({
    type: actionTypes.ENABLE_WEBSITE_SECTION_REQUEST,
    payload: {data},
  }),

  enableWebsiteSectionSuccess: (task: any) => ({
    type: actionTypes.ENABLE_WEBSITE_SECTION_SUCCESS,
    payload: task,
  }),
  enableWebsiteSectionFinish: () => ({
    type: actionTypes.ENABLE_WEBSITE_SECTION_FINISH,
  }),

  //Disable WebsiteSection
  disableWebsiteSection: (data: any) => ({
    type: actionTypes.DISABLE_WEBSITE_SECTION_REQUEST,
    payload: {data},
  }),

  disableWebsiteSectionSuccess: (task: any) => ({
    type: actionTypes.DISABLE_WEBSITE_SECTION_SUCCESS,
    payload: task,
  }),
  disableWebsiteSectionFinish: () => ({
    type: actionTypes.DISABLE_WEBSITE_SECTION_FINISH,
  }),

  //Enable WebsiteSection
  singleEnableWebsiteSection: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_WEBSITE_SECTION_REQUEST,
    payload: {data},
  }),

  singleEnableWebsiteSectionSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_WEBSITE_SECTION_SUCCESS,
    payload: task,
  }),
  singleEnableWebsiteSectionFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_WEBSITE_SECTION_FINISH,
  }),

  //Disable WebsiteSection
  singleDisableWebsiteSection: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_WEBSITE_SECTION_REQUEST,
    payload: {data},
  }),

  singleDisableWebsiteSectionSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_WEBSITE_SECTION_SUCCESS,
    payload: task,
  }),
  singleDisableWebsiteSectionFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_WEBSITE_SECTION_FINISH,
  }),

  // sort
  sortWebsiteSection: (data: SortWebsiteSectionModel) => ({
    type: actionTypes.SORT_WEBSITE_SECTION_START,
    payload: data,
  }),
  sortWebsiteSectionSuccess: (data: Array<WebsiteSectionModel>) => ({
    type: actionTypes.SORT_WEBSITE_SECTION_SUCCESS,
    payload: data,
  }),
  sortWebsiteSectionFinish: () => ({
    type: actionTypes.SORT_WEBSITE_SECTION_FINISH,
  }),
  sortWebsiteSectionReset: () => ({
    type: actionTypes.SORT_WEBSITE_SECTION_RESET,
  }),

  // website block list
  getWebsiteBlockOption: () => ({
    type: actionTypes.GET_WEBSITE_BLOCK_OPTION_START,
  }),
  getWebsiteBlockOptionSuccess: (data: {websiteBlockItem: WebsiteSectionBlockListModel[]}) => ({
    type: actionTypes.GET_WEBSITE_BLOCK_OPTION_SUCCESS,
    payload: data,
  }),
  getWebsiteBlockOptionFinish: () => ({
    type: actionTypes.GET_WEBSITE_BLOCK_OPTION_FINISH,
  }),
}
