import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'

import {toast} from 'react-toastify'
import * as cms from 'src/app/modules/cms'
import * as faqCategoryRedux from 'src/app/modules/cms/components/faqCategory/redux'
import * as Yup from 'yup'

import {StateParamsModel} from 'src/app/modules/common/Model'
import * as productCategoryRedux from '../../productCategory'
import * as productSubCategoryRedux from '../../productSubCategory'
import * as productTagRedux from '../../productTag'
import * as productManagerRedux from '../index'

import Modal from 'rsuite/Modal'
import {ProductCategoryModel} from '../../productCategory/Model/ProductCategoryModel'
//Tab Component
import {BannerModel} from 'src/app/modules/cms/components/banner/Model'
import * as bannerRedux from 'src/app/modules/cms/components/banner/redux'
import {ContentOptionModal} from 'src/app/modules/cms/components/content/Model'
import {SliderComponent} from 'src/app/modules/cms/components/content/components/TabComponents/SliderComponent'
import {FaqCategoryModel} from 'src/app/modules/cms/components/faqCategory/Model'
import {IMenuState} from 'src/app/modules/cms/components/menu'
import {MenuOptionModal} from 'src/app/modules/cms/components/menu/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import {ProductTagModel} from '../../productTag/Model/ProductTagModel'
import {ProductOptionModel} from '../Model'
import {ProductManagerModel} from '../Model/ProductManagerModel'
import {AdditionalSection} from './productTabComponents/AdditionalSection'
import {ApplyNowComponent} from './productTabComponents/ApplyNowComponent'
import {ComparisonParamComponent} from './productTabComponents/ComparisonComponent'
import {FaqComponent} from './productTabComponents/FaqComponent'
import {FeaturesComponent} from './productTabComponents/FeaturesComponent'
import {GeneralComponent} from './productTabComponents/GeneralComponent'
import {MediaComponent} from './productTabComponents/MediaComponent'
import {PageHeaderSettingComponent} from './productTabComponents/PageHeaderSettingComponent'
import {ProductBenefitComponent} from './productTabComponents/ProductBenefitComponent'
import {RelatedComponent} from './productTabComponents/RelatedProductsComp'
import {RequiredDocumentsComponent} from './productTabComponents/RequiredDocumentComp'
import {TutorialComponent} from './productTabComponents/TutorialComponent'
import * as tutorial from '../../../../tutorial/components/tutorialManager/index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = {
  title: Yup.string().required('Name is required'),
  faqOptionId: Yup.string().required('Faq option is required'),
  media: Yup.object().shape({
    mediaTypeId: Yup.string().required('Media Type is required'),
  }),
  interestRateId: Yup.string().required('Interest rate is required'),
  shortDescription: Yup.string().max(400, 'Short Description exceeds 400 words').nullable(),
  shortDescriptionNp: Yup.string()
    .max(400, 'Short Description Nepali exceeds 400 words')
    .nullable(),
  productApplyId: Yup.string().required('Product apply field is required'),
  productCategoryId: Yup.string().required('Product category field is required'),
  productComparisonId: Yup.string().required('Product comparison field is required'),
  productDocumentId: Yup.string().required('Product document field is required'),
  productFeatureId: Yup.string().required('Product feature field is required'),
  productLeadFormId: Yup.string().required('Product lead form field is required'),
  productRelatedId: Yup.string().required('Product related field is required'),
  reviewId: Yup.string().required('Product review data field is required'),
  featuredInHomepage: Yup.string().required('Feature in homepage field is required'),
  competitorStatusId: Yup.string().required('Competitor status field is required'),
}

const AddProductManager = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()

  const [validationState, setValidationState] = useState(FORM_VALIDATION)
  const {
    productBreadcrumbOption,
    productBannerOption,
    sliderSectionOptions,
    productPopularity,
    productComparisonStatus,
    productApplyNowOption,
    productCompetitorStatus,
    productDocumentOption,
    productFaqOption,
    productTutorialOption,
    productFeatureOption,
    productBenefitsOption,
    productInterestRateOption,
    productLeadFormOption,
    productMediaType,
    productRelatedOption,
    productReviewOption,
    allProductManager,
    loading,
    success,
  } = useSelector((state: any) => state.productManager)

  const tutorialData: any = useSelector((state: any) => state?.tutorial?.data?.tutorial)

  const {
    data: {productCategory},
  } = useSelector((state: any) => state.productCategory)

  const {
    data: {productSubCategory},
  } = useSelector((state: any) => state.productSubCategory)

  const {
    data: {productTag},
  } = useSelector((state: any) => state.productTag)

  const {
    data: {faqCategory},
  } = useSelector((state: any) => state.faqCategory)
  const {topMenu} = useSelector((state: IMenuState | any) => state.menuManager)

  const {
    data: {banner},
  } = useSelector((state: any) => state.banner)

  useEffect(() => {
    dispatch(bannerRedux.actions.getAllBanner())
    dispatch(productManagerRedux.actions.getProductSliderSection())
    dispatch(faqCategoryRedux.actions.getAllFaqCategory({status: true}))
    dispatch(productManagerRedux.actions.getConstantProductBannerOption())
    dispatch(productManagerRedux.actions.getProductPopularity())
    dispatch(productManagerRedux.actions.getProductComparisonStatus())
    dispatch(productManagerRedux.actions.getProductApplyNowOption())
    dispatch(productManagerRedux.actions.getProductCompetitorStatus())
    dispatch(productManagerRedux.actions.getProductDocumentOption())
    dispatch(productManagerRedux.actions.getProductFaqOption())
    dispatch(productManagerRedux.actions.getProductTutorialOption())
    dispatch(productManagerRedux.actions.getProductFeatureOption())
    dispatch(productManagerRedux.actions.getProductBenefitOption())
    dispatch(productManagerRedux.actions.getProductInterestRateOption())
    dispatch(productManagerRedux.actions.getProductLeadFormOption())
    dispatch(productManagerRedux.actions.getProductMediaType())
    dispatch(productManagerRedux.actions.getProductRelatedOption())
    dispatch(productManagerRedux.actions.getProductReviewOption())
    dispatch(productCategoryRedux.actions.getAllProductCategory())
    dispatch(productSubCategoryRedux.actions.getAllProductSubCategory())
    dispatch(productTagRedux.actions.getAllProductTag())
    dispatch(cms.menu.actions.getTopMenu())
    dispatch(productManagerRedux.actions.getProductBreadCrumbOptions())
    dispatch(tutorial.actions.getAllTutorial())
  }, [])
  useEffect(() => {
    if (!isEmpty(editSelectedData?.id)) {
      dispatch(productManagerRedux.actions.getAllProductManager(editSelectedData.id))
    } else {
      dispatch(productManagerRedux.actions.getAllProductManager(editSelectedData.id))
    }
  }, [])

  const multiLanguage = checkMultiLanguage()

  const bannerData = banner?.map((items: BannerModel) => ({
    label: multiLanguage ? items.name : items.title,
    value: items.id,
  }))

  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState(
    editSelectedData?.productCategoryId || ''
  )

  const bannerOptions = productBannerOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))

  const breadcrumbOptions = productBreadcrumbOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))

  const productManagerOptions = allProductManager?.productManager?.map(
    (items: ProductManagerModel) => ({
      label: items.title,
      value: items.id,
    })
  )

  const productCategoryOptions = productCategory?.map((items: ProductCategoryModel) => ({
    label: items.name,
    value: items.id,
  }))

  const productSubCategoryOptions = productSubCategory
    ?.filter(
      (item: any) =>
        selectedProductCategoryId && item?.productCategory?.id?.includes(selectedProductCategoryId)
    )
    .map((items: ProductCategoryModel) => ({
      label: items.name,
      value: items.id,
    }))

  const productTagOptions = productTag
    ?.filter(
      (item: any) =>
        selectedProductCategoryId && item?.productCategory?.id?.includes(selectedProductCategoryId)
    )
    .map((items: ProductTagModel) => ({
      label: items.name,
      value: items.id,
    }))

  const productPopularityOptions = productPopularity?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productComparisonStatusOptions = productComparisonStatus?.map(
    (items: ProductOptionModel) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    })
  )

  const productApplyNowOptions = productApplyNowOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productCompetitorStatusOptions = productCompetitorStatus?.map(
    (items: ProductOptionModel) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    })
  )
  const sliderSectionOption = sliderSectionOptions?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))
  const productDocumentOptions = productDocumentOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productFaqOptions = productFaqOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productTutorialOptions = productTutorialOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productFeatureOptions = productFeatureOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productBenefitsOptions = productBenefitsOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productInterestRateOptions = productInterestRateOption?.map(
    (items: ProductOptionModel) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    })
  )
  const topMenuOptions = topMenu?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))
  const productLeadFormOptions = productLeadFormOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productMediaTypeOptions = productMediaType?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productRelatedOptions = productRelatedOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const productReviewOptions = productReviewOption?.map((items: ProductOptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const flattenFaqCategories = (categories: any[]) => {
    return categories.reduce((acc, item) => {
      // Map the main parent item
      acc.push({
        label: item.name,
        value: item.id,
      })

      // If the item has children, map them as well
      if (item.children) {
        const childItems = item.children.map((child: any) => ({
          label: child.name,
          value: child.id,
        }))
        acc.push(...childItems)
      }

      return acc
    }, [])
  }

  const faqCategoryOptions = flattenFaqCategories(faqCategory)

  const tutorialOptions = tutorialData?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(productManagerRedux?.actions.getProductManager(params))
      isEmpty(editSelectedData)
        ? toast.success('Product manager added successfully')
        : toast.success('Product manager edited successfully')
      dispatch(productManagerRedux?.actions?.resetProductManager())
      handleClose()
    }
  }, [success])

  const hideFeaturesTab = checkBackendSetting('hideFeaturesTab')
  const hideFaqTab = checkBackendSetting('hideFaqTab')
  const hideComparisionParameterTab = checkBackendSetting('hideComparisionParameterTab')
  const showSliderSection = checkBackendSetting('showSliderSection')
  const showProductBenefitsTab = checkBackendSetting('showProductBenefitsTab')
  const showPageHeaderSettingsTab = checkBackendSetting('showPageHeaderSettingsTab')

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        enforceFocus={false}
        keyboard={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Product Manager </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: '80vh'}}>
          <div>
            <Formik
              initialValues={{
                title: '',
                topmenuIds: [],
                bookAppointment: 'no',
                accountBenefitOption: 'no',
                applyData: {
                  text: '',
                  textNp: '',
                  firstButtonText: '',
                  firstButtonTextNp: '',
                  firstButtonLink: '',
                  secondButtonText: '',
                  secondButtonTextNp: '',
                  secondButtonLink: '',
                },
                competitorStatusId: '',
                description: '',
                descriptionNp: '',
                shortDescription: '',
                shortDescriptionNp: '',
                documentData: {
                  title: '',
                  titleNp: '',
                  description: '',
                  descriptionNp: '',
                },
                documents: [{description: '', descriptionNp: ''}],
                faqOptionId: '',
                featureData: {
                  title: '',
                  titleNp: '',
                  description: '',
                  descriptionNp: '',
                  helpText: '',
                  helpTextNp: '',
                },
                benefitId: [
                  {
                    title: '',
                    titleNp: '',
                    description: '',
                    descriptionNp: '',
                  },
                ],
                comparisonData: [],
                blocks: [{label: '', value: ''}],
                features: [{description: '', descriptionNp: ''}],
                additionalData: [{text: '', textNp: '', description: '', descriptionNp: ''}],
                interestRateId: '',
                media: {
                  thumbImage: '',
                  mediaTypeId: '',
                  image: '',
                  iframe: '',
                },
                pageHeader: {
                  headerImage: '',
                  tagLine: '',
                  tagLineNp: '',
                  firstCtaButton: '',
                  firstCtaButtonNp: '',
                  firstCtaButtonLink: '',
                  secondCtaButton: '',
                  secondCtaButtonNp: '',
                  secondCtaButtonLink: '',
                },
                productApplyId: '',
                productCategoryId: '',
                productSubCategoryId: '',
                productComparisonId: '',
                productDocumentId: '',
                productFeatureId: '',
                benefitSettingId: '',
                productLeadFormId: '',
                productRelatedId: '',
                relatedData: [
                  {
                    id: '',
                    productId: '',
                    text: '',
                    textNp: '',
                    subText: '',
                    subTextNp: '',
                    buttonText: '',
                    buttonTextNp: '',
                    buttonLink: '',
                    order: '',
                    relatedProduct: {
                      productId: '',
                    },
                  },
                ],
                reviewId: '',
                faqId: '',
                tags: [],
                featuredInHomepage: '',
                status: '',
                slug: '',
                show_breadcrumb_id: '',
                show_banner_id: '',
                bannerId: [],
                show_slider_id: '',
                sliderData: [
                  {
                    title: '',
                    title_np: '',
                    imageData: [{name: '', name_np: '', image: '', link: ''}],
                  },
                ],
                tutorials: [],
              }}
              validationSchema={Yup.object().shape(validationState)}
              onSubmit={(values) => {
                const updatedRelatedProductsData: any = []
                values.relatedData.map((dta) => {
                  updatedRelatedProductsData.push({
                    buttonLink: dta.buttonLink,
                    buttonText: dta.buttonText,
                    buttonTextNp: dta.buttonTextNp,
                    productId: dta.productId || dta?.relatedProduct?.productId,
                    subText: dta.subText,
                    subTextNp: dta.subTextNp,
                    text: dta.text,
                    textNp: dta.textNp,
                    order: dta.order,
                  })
                })

                const formData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.title.replace(/\s/g, '-').toLowerCase(),
                  sliderData: values?.sliderData?.map((main: any) => ({
                    title: main.title,
                    title_np: main.title_np,
                    imageData: main.imageData.map((icons: any) => ({
                      name: icons.name,
                      name_np: icons.name_np,
                      image: icons.image,
                      link: icons.link,
                    })),
                  })),
                  featuredInHomepage: values.featuredInHomepage === 'yes' ? true : false,
                  topmenuIds: !isEmpty(values?.topmenuIds)
                    ? values?.topmenuIds?.map((item: string) => ({
                        id: item,
                      }))
                    : null,
                  bookAppointment: values.bookAppointment === 'yes' ? true : false,
                  accountBenefitOption: values.accountBenefitOption === 'yes' ? true : false,
                  features: !isEmpty(values?.features)
                    ? values?.features?.map((items: {[key: string]: string}) => ({
                        ...items,
                        id: !isEmpty(editSelectedData) ? items.id : null,
                      }))
                    : [{description: '', descriptionNp: ''}],
                  blocks: !isEmpty(values?.blocks)
                    ? values?.blocks?.map((items: {[key: string]: string}) => ({
                        ...items,
                        id: !isEmpty(editSelectedData) ? items.id : null,
                      }))
                    : [{value: '', label: ''}],
                  documents: !isEmpty(values?.documents)
                    ? values?.documents?.map((documents: {[key: string]: string}) => ({
                        ...documents,
                        id: !isEmpty(editSelectedData) ? documents.id : null,
                      }))
                    : [{description: '', descriptionNp: ''}],
                  tags: !isEmpty(values?.tags)
                    ? values?.tags?.map((item: string) => ({
                        id: item,
                      }))
                    : null,
                  bannerId: !isEmpty(values?.bannerId)
                    ? values?.bannerId?.map((item: string) => ({
                        id: item,
                      }))
                    : null,
                  benefitId: values?.benefitId?.map((main: any) => ({
                    title: main.title,
                    titleNp: main.titleNp,
                    description: main.description,
                    descriptionNp: main.descriptionNp,
                  })),
                  pageHeader: [
                    {
                      headerImage: values.pageHeader.headerImage,
                      tagLine: values.pageHeader.tagLine,
                      tagLineNp: values.pageHeader.tagLineNp,
                      firstCtaButton: values.pageHeader.firstCtaButton,
                      firstCtaButtonNp: values.pageHeader.firstCtaButtonNp,
                      firstCtaButtonLink: values.pageHeader.firstCtaButtonLink,
                      secondCtaButton: values.pageHeader.secondCtaButton,
                      secondCtaButtonNp: values.pageHeader.secondCtaButtonNp,
                      secondCtaButtonLink: values.pageHeader.secondCtaButtonLink,
                    },
                  ],
                  media: [
                    {
                      thumbImage: values.media.thumbImage,
                      mediaTypeId: values.media.mediaTypeId,
                      image: values.media.image,
                      iframe: values.media.iframe,
                    },
                  ],
                  featureData: [
                    {
                      title: values.featureData.title,
                      titleNp: values.featureData.titleNp,
                      description: values.featureData.description,
                      descriptionNp: values.featureData.descriptionNp,
                      helpText: values.featureData.helpText,
                      helpTextNp: values.featureData.helpTextNp,
                    },
                  ],
                  applyData: [
                    {
                      text: values.applyData.text,
                      textNp: values.applyData.textNp,
                      firstButtonText: values.applyData.firstButtonText,
                      firstButtonTextNp: values.applyData.firstButtonTextNp,
                      firstButtonLink: values.applyData.firstButtonLink,
                      secondButtonText: values.applyData.secondButtonText,
                      secondButtonTextNp: values.applyData.secondButtonTextNp,
                      secondButtonLink: values.applyData.secondButtonLink,
                    },
                  ],
                  relatedData: updatedRelatedProductsData,
                  documentData: [
                    {
                      title: values.documentData.title,
                      titleNp: values.documentData.titleNp,
                      description: values.documentData.description,
                      descriptionNp: values.documentData.descriptionNp,
                    },
                  ],
                  additionalData: values?.additionalData?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                  tutorials: !isEmpty(values?.tutorials)
                    ? values?.tutorials?.map((item: string) => ({
                        id: item,
                      }))
                    : null,
                  status: true,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    productManagerRedux.actions.updateProductManager(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(productManagerRedux.actions.addProductManager(formData))
                }
              }}
            >
              {({touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'title',
                      'bookAppointment',
                      'accountBenefitOption',
                      'title_np',
                      'topmenuIds',
                      'slug',
                      'description',
                      'descriptionNp',
                      'shortDescriptionNp',
                      'shortDescription',
                      'productCategoryId',
                      'productSubCategoryId',
                      'tags',
                      'blocks',
                      'productPopularityId',
                      'interestRateId',
                      'productLeadFormId',
                      'reviewId',
                      'review',
                      'competitorStatusId',
                      'pageHeader',
                      'media',
                      'productFeatureId',
                      'benefitSettingId',
                      'faqOptionId',
                      'faqId',
                      'tutorialOptionId',
                      'tutorials',
                      'productDocumentId',
                      'productRelatedId',
                      'documentData',
                      'documents',
                      'featureData',
                      'benefitId',
                      'features',
                      'productApplyId',
                      'applyData',
                      'additionalData',
                      'productComparisonId',
                      'show_breadcrumb_id',
                      'bannerId',
                      'show_slider_id',
                      'sliderData',
                      'comparisonData',
                    ]
                    fields.forEach((field) => {
                      return setFieldValue(field, editSelectedData[field], false)
                    })
                    setFieldValue(
                      'featuredInHomepage',
                      editSelectedData?.featuredInHomepage === true ? 'yes' : 'no',
                      false
                    )
                    setFieldValue(
                      'bookAppointment',
                      editSelectedData?.bookAppointment ? 'yes' : 'no',
                      false
                    )
                    setFieldValue(
                      'accountBenefitOption',
                      editSelectedData?.accountBenefitOption ? 'yes' : 'no',
                      false
                    )

                    setFieldValue(`media.mediaTypeId`, editSelectedData?.mediaTypeId, false)
                    setFieldValue(
                      'relatedData',
                      editSelectedData?.relatedSetting?.productRelatedSetting
                    )
                    setFieldValue('show_banner_id', editSelectedData?.show_banner_id)
                    setFieldValue('show_breadcrumb_id', editSelectedData?.show_breadcrumb_id)
                    editSelectedData?.relatedSetting?.productRelatedSetting?.forEach(
                      (item: any, index: any) => {
                        setFieldValue(
                          `relatedData[${index}].productId`,
                          item?.relatedProduct?.productId
                        )
                      }
                    )
                  }
                }, [])

                useEffect(() => {
                  if (actionType === 'Add') {
                    setFieldValue(
                      'show_breadcrumb_id',
                      breadcrumbOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'show_banner_id',
                      bannerOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'productLeadFormId',
                      productLeadFormOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                    setFieldValue(
                      'interestRateId',
                      productInterestRateOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'reviewId',
                      productReviewOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                    setFieldValue(
                      'competitorStatusId',
                      productCompetitorStatusOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      `media.mediaTypeId`,
                      productMediaTypeOptions
                        ?.filter((items: any) => items.systemName === 'image')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'productFeatureId',
                      productFeatureOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'benefitSettingId',
                      productBenefitsOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item?.value)
                        .toString(),
                      false
                    )

                    setFieldValue(
                      'faqOptionId',
                      productFaqOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'tutorialOptionId',
                      productTutorialOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'productDocumentId',
                      productDocumentOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'productRelatedId',
                      productRelatedOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'show_slider_id',
                      sliderSectionOption
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'productApplyId',
                      productApplyNowOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'productComparisonId',
                      productComparisonStatusOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue('featuredInHomepage', 'no', false)
                  }
                }, [
                  breadcrumbOptions.length,
                  bannerOptions.length,
                  productLeadFormOptions.length,
                  productInterestRateOptions.length,
                  productReviewOptions.length,
                  productCompetitorStatusOptions.length,
                  productMediaTypeOptions.length,
                  productFeatureOptions.length,
                  productBenefitsOptions.length,
                  productFaqOptions.length,
                  productTutorialOptions.length,
                  productDocumentOptions.length,
                  productRelatedOptions.length,
                  sliderSectionOption.length,
                  productApplyNowOptions.length,
                  productComparisonStatusOptions.length,
                ])

                useEffect(() => {
                  setSelectedProductCategoryId(values?.productCategoryId)
                }, [values?.productCategoryId])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row' data-cy='product-manager-tab'>
                        <Tabs
                          defaultActiveKey='general'
                          id='uncontrolled-tab-example'
                          className='mb-5'
                        >
                          <Tab eventKey='general' title='General' data-cy='general-tab-content'>
                            <div className='card-body bg-white'>
                              <GeneralComponent
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                values={values}
                                breadcrumbOptions={breadcrumbOptions}
                                bannerOptions={bannerOptions}
                                bannerData={bannerData}
                                topMenuOptions={topMenuOptions}
                                editSelectedData={editSelectedData}
                                productCategoryOptions={productCategoryOptions}
                                productSubCategoryOptions={productSubCategoryOptions}
                                productTagOptions={productTagOptions}
                                productPopularityOptions={productPopularityOptions}
                                leadFormOptions={productLeadFormOptions}
                                interestRateOptions={productInterestRateOptions}
                                reviewOptions={productReviewOptions}
                                competitorOptions={productCompetitorStatusOptions}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </Tab>

                          <Tab eventKey='mediaSection' title='Media' data-cy='media-tab-content'>
                            <div className='card-body bg-white'>
                              <MediaComponent
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                values={values}
                                editSelectedData={editSelectedData}
                                mediaTypeOptions={productMediaTypeOptions}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </Tab>

                          {!hideFeaturesTab && (
                            <Tab
                              eventKey='featuresSection'
                              title='Features'
                              data-cy='features-tab-content'
                            >
                              <div className='card-body bg-white'>
                                <FeaturesComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  editSelectedData={editSelectedData}
                                  featuresOption={productFeatureOptions}
                                  validationState={validationState}
                                  setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>
                          )}

                          {!hideFaqTab && (
                            <Tab eventKey='faqSection' title='Faq' data-cy='faq-tab-content'>
                              <div className='card-body bg-white'>
                                <FaqComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  faqCategoryOptions={faqCategoryOptions}
                                  faqOption={productFaqOptions}
                                  validationState={validationState}
                                  setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>
                          )}

                          <Tab
                            eventKey='tutorialSection'
                            title='Tutorial'
                            data-cy='tutorial-tab-content'
                          >
                            <div className='card-body bg-white'>
                              <TutorialComponent
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                values={values}
                                tutorialCategoryOptions={tutorialOptions}
                                tutorialOption={productTutorialOptions}
                                validationState={validationState}
                                setValidationState={setValidationState}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </Tab>

                          <Tab
                            eventKey='requiredDocument'
                            title='Required Documents'
                            data-cy='doc-tab-content'
                          >
                            <div className='card-body bg-white'>
                              <RequiredDocumentsComponent
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                values={values}
                                editSelectedData={editSelectedData}
                                documentOptions={productDocumentOptions}
                                validationState={validationState}
                                setValidationState={setValidationState}
                                optionName='productDocumentId'
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </Tab>
                          <Tab
                            eventKey='relatedProducts'
                            title='Related Products'
                            data-cy='products-tab-content'
                          >
                            <div className='card-body bg-white'>
                              <RelatedComponent
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                values={values}
                                editSelectedData={editSelectedData}
                                relatedOptions={productRelatedOptions}
                                managerOptions={productManagerOptions}
                                validationState={validationState}
                                setValidationState={setValidationState}
                                optionName='productRelatedId'
                                optionTitle='Products'
                                relatedOptionIdName='productId'
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </Tab>

                          <Tab
                            eventKey='applyNow'
                            title='Apply Now'
                            data-cy='apply-now-tab-content'
                          >
                            <div className='card-body bg-white'>
                              <ApplyNowComponent
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                values={values}
                                applyNowOptions={productApplyNowOptions}
                                validationState={validationState}
                                setValidationState={setValidationState}
                                optionName='productApplyId'
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </Tab>
                          <Tab
                            eventKey='additionalSection'
                            title='Additional Section'
                            data-cy='section-tab-content'
                          >
                            <div className='card-body bg-white'>
                              <AdditionalSection
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                values={values}
                                editSelectedData={editSelectedData}
                              />
                            </div>
                          </Tab>

                          {!hideComparisionParameterTab && (
                            <Tab
                              eventKey='comparisonStatus'
                              title='Comparison Parameters'
                              data-cy='parameters-tab-content'
                            >
                              <div className='card-body bg-white'>
                                <ComparisonParamComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  editSelectedData={editSelectedData}
                                  productComparisonStatusOptions={productComparisonStatusOptions}
                                />
                              </div>
                            </Tab>
                          )}

                          {showPageHeaderSettingsTab && (
                            <Tab
                              eventKey='pageHeaderSettings'
                              title='Page Header Settings'
                              data-cy='header-tab-content'
                            >
                              <div className='card-body bg-white'>
                                <PageHeaderSettingComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  editSelectedData={editSelectedData}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>
                          )}

                          {showSliderSection && (
                            <Tab
                              eventKey='sliderSection'
                              title='Slider Section'
                              data-cy='slider-tab-content'
                            >
                              <div className='card-body bg-white'>
                                <SliderComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  sliderSectionOptions={sliderSectionOption}
                                  // validationState={validationState}
                                  // setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>
                          )}

                          {showProductBenefitsTab && (
                            <Tab
                              eventKey='productBenefitSection'
                              title='Product Benefits'
                              data-cy='benefits-tab-content'
                            >
                              <div className='card-body bg-white'>
                                <ProductBenefitComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  editSelectedData={editSelectedData}
                                  benefitOption={productBenefitsOptions}
                                  // validationState={validationState}
                                  // setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>
                          )}
                        </Tabs>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddProductManager
