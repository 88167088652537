import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const Tutorial = `${API_URL}/tutorial`

export const service = {
  getAllTutorial: () => {
    return axios.get(`${Tutorial}/list`)
  },
  getTutorial: (params: ParamsModel) => {
    return axios.get(Tutorial, {params})
  },

  addTutorial: (data: any) => {
    return axios.post(Tutorial, data)
  },

  updateTutorial: (body: any, id: string) => {
    return axios.patch(`${Tutorial}/${id}`, body)
  },

  deleteTutorial: (data: DeleteModel) => {
    return axios.delete(Tutorial, {data})
  },
  singleActivateTutorial: (id: any) => {
    return axios.patch(`${Tutorial}/enable`, id)
  },

  singleDeactivateTutorial: (id: any) => {
    return axios.patch(`${Tutorial}/disable`, id)
  },

  sortTutorial: (body: any) => {
    return axios.patch(`${Tutorial}/sort`, body)
  },
}
