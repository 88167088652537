export const actionTypes = {
  // get
  GET_ALL_WEBSITE_SECTION_START: 'GET_ALL_WEBSITE_SECTION_START',
  GET_ALL_WEBSITE_SECTION_SUCCESS: 'GET_ALL_WEBSITE_SECTION_SUCCESS',
  GET_ALL_WEBSITE_SECTION_FINISH: 'GET_ALL_WEBSITE_SECTION_FINISH',

  // get website service
  GET_WEBSITE_SECTION_START: 'GET_WEBSITE_SECTION_START',
  GET_WEBSITE_SECTION_SUCCESS: 'GET_WEBSITE_SECTION_SUCCESS',
  GET_WEBSITE_SECTION_FINISH: 'GET_WEBSITE_SECTION_FINISH',

  // add website
  ADD_WEBSITE_SECTION_START: 'ADD_WEBSITE_SECTION_START',
  ADD_WEBSITE_SECTION_SUCCESS: 'ADD_WEBSITE_SECTION_SUCCESS',
  ADD_WEBSITE_SECTION_FINISH: 'ADD_WEBSITE_SECTION_FINISH',
  RESET_WEBSITE_SECTION: 'RESET_WEBSITE_SECTION',

  // update WEBSITE_SECTION
  UPDATE_WEBSITE_SECTION_START: 'UPDATE_WEBSITE_SECTION_START',
  UPDATE_WEBSITE_SECTION_SUCCESS: 'UPDATE_WEBSITE_SECTION_SUCCESS',
  UPDATE_WEBSITE_SECTION_FINISH: 'UPDATE_WEBSITE_SECTION_FINISH',

  // delete WEBSITE_SECTION
  DELETE_WEBSITE_SECTION_START: 'DELETE_WEBSITE_SECTION_START',
  DELETE_WEBSITE_SECTION_SUCCESS: 'DELETE_WEBSITE_SECTION_SUCCESS',
  DELETE_WEBSITE_SECTION_FINISH: 'DELETE_WEBSITE_SECTION_FINISH',

  // Enable WEBSITE_SECTION
  ENABLE_WEBSITE_SECTION_REQUEST: 'ENABLE_WEBSITE_SECTION_REQUEST',
  ENABLE_WEBSITE_SECTION_SUCCESS: 'ENABLE_WEBSITE_SECTION_SUCCESS',
  ENABLE_WEBSITE_SECTION_FINISH: 'ENABLE_WEBSITE_SECTION_FINISH',

  // Disable WEBSITE_SECTION
  DISABLE_WEBSITE_SECTION_REQUEST: 'DISABLE_WEBSITE_SECTION_REQUEST',
  DISABLE_WEBSITE_SECTION_SUCCESS: 'DISABLE_WEBSITE_SECTION_SUCCESS',
  DISABLE_WEBSITE_SECTION_FINISH: 'DISABLE_WEBSITE_SECTION_FINISH',

  // Enable WEBSITE_SECTION
  SINGLE_ENABLE_WEBSITE_SECTION_REQUEST: 'SINGLE_ENABLE_WEBSITE_SECTION_REQUEST',
  SINGLE_ENABLE_WEBSITE_SECTION_SUCCESS: 'SINGLE_ENABLE_WEBSITE_SECTION_SUCCESS',
  SINGLE_ENABLE_WEBSITE_SECTION_FINISH: 'SINGLE_ENABLE_WEBSITE_SECTION_FINISH',

  // Disable WEBSITE_SECTION
  SINGLE_DISABLE_WEBSITE_SECTION_REQUEST: 'SINGLE_DISABLE_WEBSITE_SECTION_REQUEST',
  SINGLE_DISABLE_WEBSITE_SECTION_SUCCESS: 'SINGLE_DISABLE_WEBSITE_SECTION_SUCCESS',
  SINGLE_DISABLE_WEBSITE_SECTION_FINISH: 'SINGLE_DISABLE_WEBSITE_SECTION_FINISH',

  // sort
  SORT_WEBSITE_SECTION_START: 'SORT_WEBSITE_SECTION_START',
  SORT_WEBSITE_SECTION_SUCCESS: 'SORT_WEBSITE_SECTION_SUCCESS',
  SORT_WEBSITE_SECTION_FINISH: 'SORT_WEBSITE_SECTION_FINISH',
  SORT_WEBSITE_SECTION_RESET: 'SORT_WEBSITE_SECTION_RESET',

  // GET DOCUMENT_OPTION
  GET_WEBSITE_BLOCK_OPTION_START: 'GET_WEBSITE_BLOCK_OPTION_START',
  GET_WEBSITE_BLOCK_OPTION_SUCCESS: 'GET_WEBSITE_BLOCK_OPTION_SUCCESS',
  GET_WEBSITE_BLOCK_OPTION_FINISH: 'GET_WEBSITE_BLOCK_OPTION_FINISH',
}
