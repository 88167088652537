import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {WebsiteSectionModel, SortWebsiteSectionModel} from '../Model/WebsiteSectionModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const WEBSITE_SECTION_API = `${API_URL}/websiteSection`

export const service = {
  getWebsiteSection: (params: ParamsModel) => {
    return axios.get(WEBSITE_SECTION_API, {params})
  },
  getAllWebsiteSection: () => {
    return axios.get(`${WEBSITE_SECTION_API}/list`)
  },

  addWebsiteSection: (data: any) => {
    return axios.post(WEBSITE_SECTION_API, data)
  },

  updateWebsiteSection: (body: WebsiteSectionModel, id: string) => {
    return axios.patch(`${WEBSITE_SECTION_API}/${id}`, body)
  },

  deleteWebsiteSection: (data: {id: string}) => {
    return axios.delete(WEBSITE_SECTION_API, {data})
  },

  enableWebsiteSection: (data: Array<string>) => {
    const selectedWebsiteSection = {
      websiteSectionId: data,
    }
    return axios.patch(`${WEBSITE_SECTION_API}/enable`, selectedWebsiteSection)
  },

  disableWebsiteSection: (data: Array<string>) => {
    const selectedWebsiteSection = {
      websiteSectionId: data,
    }
    return axios.patch(`${WEBSITE_SECTION_API}/disable`, selectedWebsiteSection)
  },

  singleEnableWebsiteSection: (data: Array<string>) => {
    const selectedWebsiteSection = {
      websiteSectionId: [data],
    }
    return axios.patch(`${WEBSITE_SECTION_API}/enable`, selectedWebsiteSection)
  },

  singleDisableWebsiteSection: (data: Array<string>) => {
    const selectedWebsiteSection = {
      websiteSectionId: [data],
    }
    return axios.patch(`${WEBSITE_SECTION_API}/disable`, selectedWebsiteSection)
  },
  sortWebsiteSection: (body: SortWebsiteSectionModel) => {
    return axios.patch(`${WEBSITE_SECTION_API}/sort`, body)
  },
  getWebsiteBlockOption: () => {
    return axios.get(`${WEBSITE_SECTION_API}/website-block-item-list`)
  },
}
