export const actionTypes = {
  // get
  GET_ALL_IPG_SERVICE_START: 'GET_ALL_IPG_SERVICE_START',
  GET_ALL_IPG_SERVICE_SUCCESS: 'GET_ALL_IPG_SERVICE_SUCCESS',
  GET_ALL_IPG_SERVICE_FINISH: 'GET_ALL_IPG_SERVICE_FINISH',

  // extended hours
  GET_IPG_SERVICE_START: 'GET_IPG_SERVICE_START',
  GET_IPG_SERVICE_SUCCESS: 'GET_IPG_SERVICE_SUCCESS',
  GET_IPG_SERVICE_FINISH: 'GET_IPG_SERVICE_FINISH',

  // add branch
  ADD_IPG_SERVICE_START: 'ADD_IPG_SERVICE_START',
  ADD_IPG_SERVICE_SUCCESS: 'ADD_IPG_SERVICE_SUCCESS',
  ADD_IPG_SERVICE_FINISH: 'ADD_IPG_SERVICE_FINISH',
  RESET_SEARCH_TAG: 'RESET_SEARCH_TAG',

  // update SEARCH_TAG
  UPDATE_IPG_SERVICE_START: 'UPDATE_IPG_SERVICE_START',
  UPDATE_IPG_SERVICE_SUCCESS: 'UPDATE_IPG_SERVICE_SUCCESS',
  UPDATE_IPG_SERVICE_FINISH: 'UPDATE_IPG_SERVICE_FINISH',

  // delete SEARCH_TAG
  DELETE_IPG_SERVICE_START: 'DELETE_IPG_SERVICE_START',
  DELETE_IPG_SERVICE_SUCCESS: 'DELETE_IPG_SERVICE_SUCCESS',
  DELETE_IPG_SERVICE_FINISH: 'DELETE_IPG_SERVICE_FINISH',

  // Enable SEARCH_TAG
  ENABLE_IPG_SERVICE_REQUEST: 'ENABLE_IPG_SERVICE_REQUEST',
  ENABLE_IPG_SERVICE_SUCCESS: 'ENABLE_IPG_SERVICE_SUCCESS',
  ENABLE_IPG_SERVICE_FINISH: 'ENABLE_IPG_SERVICE_FINISH',

  // Disable SEARCH_TAG
  DISABLE_IPG_SERVICE_REQUEST: 'DISABLE_IPG_SERVICE_REQUEST',
  DISABLE_IPG_SERVICE_SUCCESS: 'DISABLE_IPG_SERVICE_SUCCESS',
  DISABLE_IPG_SERVICE_FINISH: 'DISABLE_IPG_SERVICE_FINISH',

  // Enable SEARCH_TAG
  SINGLE_ENABLE_IPG_SERVICE_REQUEST: 'SINGLE_ENABLE_IPG_SERVICE_REQUEST',
  SINGLE_ENABLE_IPG_SERVICE_SUCCESS: 'SINGLE_ENABLE_IPG_SERVICE_SUCCESS',
  SINGLE_ENABLE_IPG_SERVICE_FINISH: 'SINGLE_ENABLE_IPG_SERVICE_FINISH',

  // Disable SEARCH_TAG
  SINGLE_DISABLE_IPG_SERVICE_REQUEST: 'SINGLE_DISABLE_IPG_SERVICE_REQUEST',
  SINGLE_DISABLE_IPG_SERVICE_SUCCESS: 'SINGLE_DISABLE_IPG_SERVICE_SUCCESS',
  SINGLE_DISABLE_IPG_SERVICE_FINISH: 'SINGLE_DISABLE_IPG_SERVICE_FINISH',

  // sort
  SORT_IPG_SERVICE_START: 'SORT_IPG_SERVICE_START',
  SORT_IPG_SERVICE_SUCCESS: 'SORT_IPG_SERVICE_SUCCESS',
  SORT_IPG_SERVICE_FINISH: 'SORT_IPG_SERVICE_FINISH',
  SORT_IPG_SERVICE_RESET: 'SORT_IPG_SERVICE_RESET',
}
