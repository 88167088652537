import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CMS_SUCCESS_STORY = `${API_URL}/successstory`

export const service = {
  getSuccessStoryData: (params: ParamsModel) => {
    return axios.get(CMS_SUCCESS_STORY, {params})
  },
  getSuccessStoryFileType: () => {
    return axios.get(`${API_URL}/successstory-file-type-option`)
  },
  getSuccessStoryList: () => {
    return axios.get(`${CMS_SUCCESS_STORY}/list`)
  },
  addSuccessStoryItem: (data: any) => {
    return axios.post(CMS_SUCCESS_STORY, data)
  },

  updateSuccessStoryItem: (body: any, id: string) => {
    return axios.put(`${CMS_SUCCESS_STORY}/${id}`, body)
  },

  deleteBulkSuccessStory: (data: DeleteModel) => {
    return axios.delete(`${CMS_SUCCESS_STORY}/bulk-delete`, {data})
  },

  activateSuccessStory: (data: Array<string>) => {
    const selectedMemberType = {
      successstory: data,
    }
    return axios.put(`${CMS_SUCCESS_STORY}/bulk-active`, selectedMemberType)
  },

  deactivateSuccessStory: (data: Array<string>) => {
    const selectedMemberType = {
      successstory: data,
    }
    return axios.put(`${CMS_SUCCESS_STORY}/bulk-inactive`, selectedMemberType)
  },

  singleActivateSuccessStory: (data: Array<string>) => {
    const selectedMemberType = {
      successstory: [data],
    }
    return axios.put(`${CMS_SUCCESS_STORY}/bulk-active`, selectedMemberType)
  },

  singleDeactivateSuccessStory: (data: Array<string>) => {
    const selectedMemberType = {
      successstory: [data],
    }
    return axios.put(`${CMS_SUCCESS_STORY}/bulk-inactive`, selectedMemberType)
  },
  sortCategories: (data: Array<string>) => {
    return axios.put(`${CMS_SUCCESS_STORY}/sort`, data)
  },
}
