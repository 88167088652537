import {FormikErrors} from 'formik'
import {cloneDeep, isEmpty} from 'lodash'
import {ChangeEvent, useEffect} from 'react'
import {FormOptionModal} from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as Yup from 'yup'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{[key: string]: string}> | any
  touched: any
  values: {[key: string]: string} | any
  tutorialOption: FormOptionModal[]
  tutorialCategoryOptions: FormOptionModal[]
  validationState: any
  setValidationState: any
  setFieldValue: any
}

export function TutorialComponent({
  handleChange,
  errors,
  touched,
  values,
  tutorialOption,
  tutorialCategoryOptions,
  validationState,
  setValidationState,
  setFieldValue,
}: Props) {
  const tutorialShowId = tutorialOption?.find(
    (item: FormOptionModal) => item.value === values.tutorialOptionId
  )

  useEffect(() => {
    if (
      !isEmpty(tutorialShowId) &&
      tutorialShowId?.systemName === 'yes' &&
      !isEmpty(values?.tutorialOptionId)
    ) {
      setValidationState({
        ...validationState,
        tutorialId: Yup.string().required('Tutorial Category is required'),
      })
    } else if (!isEmpty(tutorialShowId) && tutorialShowId?.systemName === 'no') {
      let newValidationSchema = cloneDeep(validationState)
      !isEmpty(newValidationSchema['tutorialId']) && delete newValidationSchema['tutorialId']
      setValidationState(newValidationSchema)
    }
  }, [values?.tutorialOptionId])

  return (
    <div className='row'>
      <div className='col-md-6 col-xs-12'>
        <FormSelect
          labelClassName='col-md-12'
          containerClassName='col-md-12'
          placeholder='Please select'
          label='Show Tutorial Section'
          name='tutorialOptionId'
          onChange={handleChange}
          errors={errors}
          touched={touched}
          options={tutorialOption}
          required
          values={values}
          setFieldValue={setFieldValue}
        />
      </div>

      {tutorialShowId?.systemName === 'yes' ? (
        <>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label='Tutorials'
              name='tutorials'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={tutorialCategoryOptions}
              required={tutorialShowId?.systemName === 'yes' ? true : false}
              multiple={true}
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
        </>
      ) : null}
    </div>
  )
}
