import {ErrorMessage, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'

import {isEmpty} from 'lodash'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as tutorialCategory from '../../categorySettings/index'
import * as tutorial from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  status: Yup.string().required('Status is required'),
  tutorial_category: Yup.string().required('Tutorial category is required'),
})

const AddCategory = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.tutorial)
  const [imageError, setImageError] = useState(false)

  const categoryData: any = useSelector(
    (state: any) => state?.tutorialCategory?.data?.tutorialCategory
  )

  useEffect(() => {
    dispatch(tutorialCategory.actions.getActiveTutorialCategory())
  }, [])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const categoryOptions = categoryData?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(tutorial?.actions.getTutorial(params))
      isEmpty(editSelectedData)
        ? toast.success('Tutorial added successfully')
        : toast.success('Tutorial edited successfully')
      dispatch(tutorial?.actions?.addTutorialReset())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Tutorial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                title_np: '',
                ctaButtonOne: '',
                ctaButtonOneNp: '',
                ctaButtonOneLink: '',
                ctaButtonTwo: '',
                ctaButtonTwoNp: '',
                ctaButtonTwoLink: '',
                status: 'Active',
                youtube_link: '',
                images: [
                  {
                    image: '',
                  },
                ],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {}) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                }

                //removing images with empty src
                formData.images = formData.images.filter((d) => d.image.trim() !== '')

                if (!isEmpty(editSelectedData)) {
                  dispatch(tutorial.actions.updateTutorial(formData, editSelectedData?.id))
                } else {
                  dispatch(tutorial.actions.addTutorial(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'title',
                      'title_np',
                      'youtube_link',
                      'ctaButtonOne',
                      'ctaButtonOneNp',
                      'ctaButtonOneLink',
                      'ctaButtonTwo',
                      'ctaButtonTwoNp',
                      'ctaButtonTwoLink',
                      'status',
                      'tutorial_category',
                      'images',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title (EN)'
                            label='Title (EN)'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title (NP)'
                            label='Title (NP)'
                            name='title_np'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select category'
                            label='Category'
                            name='tutorial_category'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={categoryOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Youtube link'
                            label='Youtube Link'
                            name='youtube_link'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button One'
                            label='CTA Button One'
                            name='ctaButtonOne'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button One(NP)'
                            label='CTA Button One (NP)'
                            name='ctaButtonOneNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button One Link'
                            label='CTA Button One Link'
                            name='ctaButtonOneLink'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button Two'
                            label='CTA Button Two'
                            name='ctaButtonTwo'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button Two(NP)'
                            label='CTA Button Two (NP)'
                            name='ctaButtonTwoNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='CTA Button Two Link)'
                            label='CTA Button Two Link'
                            name='ctaButtonTwoLink'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='d-flex flex-column'>
                          {values.images?.map((val, i) => {
                            return (
                              <div className='col-md-6 col-xs-12 d-flex flex-column' key={i}>
                                <FormInputMediaManager
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label={`Image ${i + 1}`}
                                  name='images'
                                  setFieldValue={(_: string, downloadLink: string) => {
                                    const updatedImages = [...values.images]
                                    updatedImages[i].image = downloadLink
                                    setFieldValue('images', updatedImages)
                                    setImageError(false)
                                  }}
                                  value={val.image}
                                  readOnly={true}
                                  showImageOnly
                                />
                                {!isEmpty(val.image) && (
                                  <>
                                    <li className='listing'>
                                      <div className='thumbImageBlock'>
                                        <button
                                          type='button'
                                          title='Remove'
                                          className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                          onClick={() => {
                                            const updatedImages = values.images.filter(
                                              (_: any, index: number) => index !== i
                                            )
                                            setFieldValue('images', updatedImages)
                                            setImageError(false)
                                          }}
                                          data-cy='modal-thumbnail-remove'
                                        >
                                          Delete
                                        </button>
                                        <img
                                          className='thumbImage w-100 h-100'
                                          src={`${imageBaseUrl}/${val?.image}`}
                                          alt='image'
                                        />
                                      </div>
                                    </li>
                                  </>
                                )}
                              </div>
                            )
                          })}
                        </div>
                        {imageError && <p className='text-danger'>Please select image</p>}
                        <button
                          type='button'
                          disabled={values.images.some((img) => img.image === '')}
                          className='btn btn-primary btn-sm ms-3 col-md-2 p-2 my-2'
                          onClick={() => setFieldValue('images', [...values.images, {image: ''}])}
                          data-cy='modal-thumbnail-add'
                        >
                          Add New Image
                        </button>
                        <br />
                        <div className='col-md-12 mt-5 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddCategory
