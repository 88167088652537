export const actionTypes = {
  // get Position
  GET_POSITION_START: 'GET_POSITION_START',
  GET_POSITION_SUCCESS: 'GET_POSITION_SUCCESS',
  GET_POSITION_FINISH: 'GET_POSITION_FINISH',

  // ADD Position
  ADD_POSITION_START: 'ADD_POSITION_START',
  ADD_POSITION_SUCCESS: 'ADD_POSITION_SUCCESS',
  ADD_POSITION_FINISH: 'ADD_POSITION_FINISH',
  ADD_POSITION_RESET: 'ADD_POSITION_RESET',

  // Update Position
  UPDATE_POSITION_START: 'UPDATE_POSITION_START',
  UPDATE_POSITION_SUCCESS: 'UPDATE_POSITION_SUCCESS',
  UPDATE_POSITION_FINISH: 'UPDATE_POSITION_FINISH',
  UPDATE_POSITION_RESET: 'UPDATE_POSITION_RESET',

  // delete Position
  DELETE_POSITION_START: 'DELETE_POSITION_START',
  DELETE_POSITION_SUCCESS: 'DELETE_POSITION_SUCCESS',
  DELETE_POSITION_FINISH: 'DELETE_POSITION_FINISH',

  // single Position
  SINGLE_ACTIVATE_POSITION_REQUEST: 'SINGLE_ACTIVATE_POSITION_REQUEST',
  SINGLE_ACTIVATE_POSITION_SUCCESS: 'SINGLE_ACTIVATE_POSITION_SUCCESS',
  SINGLE_ACTIVATE_POSITION_FINISH: 'SINGLE_ACTIVATE_POSITION_FINISH',

  // single deactive Position
  SINGLE_DEACTIVATE_POSITION_REQUEST: 'SINGLE_DEACTIVATE_POSITION_REQUEST',
  SINGLE_DEACTIVATE_POSITION_SUCCESS: 'SINGLE_DEACTIVATE_POSITION_SUCCESS',
  SINGLE_DEACTIVATE_POSITION_FINISH: 'SINGLE_DEACTIVATE_POSITION_FINISH',

  // Enable Gallery
  ENABLE_POSITION_REQUEST: 'ENABLE_POSITION_REQUEST',
  ENABLE_POSITION_SUCCESS: 'ENABLE_POSITION_SUCCESS',
  ENABLE_POSITION_FINISH: 'ENABLE_POSITION_FINISH',

  // Disable Gallery
  DISABLE_POSITION_REQUEST: 'DISABLE_POSITION_REQUEST',
  DISABLE_POSITION_SUCCESS: 'DISABLE_POSITION_SUCCESS',
  DISABLE_POSITION_FINISH: 'DISABLE_POSITION_FINISH',

  // get GALLERYs
  GET_ALL_POSITION_START: 'GET_ALL_POSITION_START',
  GET_ALL_POSITION_SUCCESS: 'GET_ALL_POSITION_SUCCESS',
  GET_ALL_POSITION_FINISH: 'GET_ALL_POSITION_FINISH',

  // sort
  SORT_POSITION_START: 'SORT_POSITION_START',
  SORT_POSITION_SUCCESS: 'SORT_POSITION_SUCCESS',
  SORT_POSITION_FINISH: 'SORT_POSITION_FINISH',
  SORT_POSITION_RESET: 'SORT_POSITION_RESET',
}
