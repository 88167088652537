import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeletePayoutLocationModel, SortPayoutLocationModel} from '../Model'
import {PayoutLocationModel} from '../Model/PayoutLocationModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const PAYOUT_LOCATION_DATA = `${API_URL}/payoutlocations`

export const service = {
  getPayoutLocation: (params: ParamsModel) => {
    return axios.get(PAYOUT_LOCATION_DATA, {params})
  },
  getAllPayoutLocation: () => {
    return axios.get(`${PAYOUT_LOCATION_DATA}/list`)
  },

  addPayoutLocation: (data: any) => {
    return axios.post(PAYOUT_LOCATION_DATA, data)
  },

  updatePayoutLocation: (body: PayoutLocationModel, id: string) => {
    return axios.put(`${PAYOUT_LOCATION_DATA}/${id}`, body)
  },

  deletePayoutLocation: (data: DeletePayoutLocationModel) => {
    return axios.delete(`${PAYOUT_LOCATION_DATA}/bulk-delete`, {data})
  },

  enablePayoutLocation: (data: Array<string>) => {
    const selectedPayoutLocation = {
      payoutLocationsId: data,
    }
    return axios.patch(`${PAYOUT_LOCATION_DATA}/enable`, selectedPayoutLocation)
  },

  disablePayoutLocation: (data: Array<string>) => {
    const selectedPayoutLocation = {
      payoutLocationsId: data,
    }
    return axios.patch(`${PAYOUT_LOCATION_DATA}/disable`, selectedPayoutLocation)
  },

  singleEnablePayoutLocation: (data: Array<string>) => {
    const selectedPayoutLocation = {
      payoutLocationsId: [data],
    }
    return axios.patch(`${PAYOUT_LOCATION_DATA}/enable`, selectedPayoutLocation)
  },

  singleDisablePayoutLocation: (data: Array<string>) => {
    const selectedPayoutLocation = {
      payoutLocationsId: [data],
    }
    return axios.patch(`${PAYOUT_LOCATION_DATA}/disable`, selectedPayoutLocation)
  },

  sortPayoutLocation: (body: SortPayoutLocationModel) => {
    return axios.patch(`${PAYOUT_LOCATION_DATA}/sort`, body)
  },
}
