import {ParamsModel} from 'src/app/modules/common/Model'
import {SuccessStoryModel, DeleteModel, SortSuccessStoryModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  //*  GET SUCCESS_STORY File Type
  getSuccessStoryFileType: () => ({
    type: actionTypes.GET_SUCCESS_STORY_FILE_TYPE_START,
  }),

  getSuccessStoryFileTypeSuccess: (data: SuccessStoryModel[]) => ({
    type: actionTypes.GET_SUCCESS_STORY_FILE_TYPE_SUCCESS,
    payload: data,
  }),
  getSuccessStoryFileTypeFinish: () => ({
    type: actionTypes.GET_SUCCESS_STORY_FILE_TYPE_FINISH,
  }),

  //*  GET SUCCESS_STORY Data
  getSuccessStoryData: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_SUCCESS_STORY_DATA_START,
    payload: params,
  }),

  getSuccessStoryDataSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_SUCCESS_STORY_DATA_SUCCESS,
    payload: data,
  }),
  getSuccessStoryDataFinish: () => ({
    type: actionTypes.GET_SUCCESS_STORY_DATA_FINISH,
  }),

  //*  GET SUCCESS_STORY List
  getSuccessStoryList: () => ({
    type: actionTypes.GET_SUCCESS_STORY_LIST_START,
  }),

  getSuccessStoryListSuccess: (data: any) => ({
    type: actionTypes.GET_SUCCESS_STORY_LIST_SUCCESS,
    payload: data,
  }),
  getSuccessStoryListFinish: () => ({
    type: actionTypes.GET_SUCCESS_STORY_LIST_FINISH,
  }),
  getSuccessStoryListError: (error: unknown) => ({
    type: actionTypes.GET_SUCCESS_STORY_LIST_FINISH,
    payload: {error},
  }),

  // * ADD CRS Item
  addSuccessStoryItem: (data: any) => ({
    type: actionTypes.ADD_SUCCESS_STORY_ITEM_START,
    payload: data,
  }),
  addSuccessStoryItemSuccess: (task: any) => ({
    type: actionTypes.ADD_SUCCESS_STORY_ITEM_SUCCESS,
    payload: task,
  }),
  addSuccessStoryItemFinish: () => ({
    type: actionTypes.ADD_SUCCESS_STORY_ITEM_FINISH,
  }),
  addSuccessStoryItemReset: () => ({
    type: actionTypes.ADD_SUCCESS_STORY_ITEM_RESET,
  }),

  //*  UPDATE SUCCESS_STORY Item
  updateSuccessStoryItem: (data: any, id: string) => ({
    type: actionTypes.UPDATE_SUCCESS_STORY_ITEM_START,
    payload: {data, id},
  }),
  updateSuccessStoryItemSuccess: (task: any) => ({
    type: actionTypes.UPDATE_SUCCESS_STORY_ITEM_SUCCESS,
    payload: task,
  }),
  updateSuccessStoryItemFinish: () => ({
    type: actionTypes.UPDATE_SUCCESS_STORY_ITEM_FINISH,
  }),
  updateSuccessStoryItemReset: () => ({
    type: actionTypes.UPDATE_SUCCESS_STORY_ITEM_RESET,
  }),

  //*  DELETE SUCCESS_STORY Item
  deleteSuccessStoryItem: (data: DeleteModel[]) => ({
    type: actionTypes.DELETE_BULK_SUCCESS_STORY_START,
    payload: {successstory: data},
  }),
  deleteSuccessStoryItemSuccess: (data: any) => ({
    type: actionTypes.DELETE_BULK_SUCCESS_STORY_SUCCESS,
    payload: data,
  }),
  deleteSuccessStoryItemFinish: () => ({
    type: actionTypes.DELETE_BULK_SUCCESS_STORY_FINISH,
  }),

  //activate SuccessStory
  activateSuccessStory: (data: any) => ({type: actionTypes.ACTIVATE_SUCCESS_STORY_START, payload: {data}}),

  activateSuccessStorySuccess: (task: any) => ({
    type: actionTypes.ACTIVATE_SUCCESS_STORY_SUCCESS,
    payload: task,
  }),
  activateSuccessStoryFinish: () => ({
    type: actionTypes.ACTIVATE_SUCCESS_STORY_FINISH,
  }),

  //deactivate SuccessStory
  deactivateSuccessStory: (data: any) => ({type: actionTypes.DEACTIVATE_SUCCESS_STORY_START, payload: {data}}),

  deactivateSuccessStorySuccess: (task: any) => ({
    type: actionTypes.DEACTIVATE_SUCCESS_STORY_SUCCESS,
    payload: task,
  }),
  deactivateSuccessStoryFinish: () => ({
    type: actionTypes.DEACTIVATE_SUCCESS_STORY_FINISH,
  }),

  //activate SuccessStory
  singleActivateSuccessStory: (data: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_SUCCESS_STORY_START,
    payload: {data},
  }),

  singleActivateSuccessStorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_SUCCESS_STORY_SUCCESS,
    payload: task,
  }),
  singleActivateSuccessStoryFinish: () => ({
    type: actionTypes.SINGLE_ACTIVATE_SUCCESS_STORY_FINISH,
  }),

  //deactivate SuccessStory
  singleDeactivateSuccessStory: (data: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_SUCCESS_STORY_START,
    payload: {data},
  }),

  singleDeactivateSuccessStorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_SUCCESS_STORY_SUCCESS,
    payload: task,
  }),
  singleDeactivateSuccessStoryFinish: () => ({
    type: actionTypes.SINGLE_DEACTIVATE_SUCCESS_STORY_FINISH,
  }),

  // sort csr
  // sort category
  sortSuccessStory: (data: SortSuccessStoryModel) => ({
    type: actionTypes.SORT_SUCCESS_STORY_START,
    payload: {data},
  }),
  sortSuccessStorySuccess: (data: Array<SortSuccessStoryModel>) => ({
    type: actionTypes.SORT_SUCCESS_STORY_SUCCESS,
    payload: {data},
  }),
  sortSuccessStoryFinish: () => ({
    type: actionTypes.SORT_SUCCESS_STORY_FINISH,
  }),
  sortSuccessStoryReset: () => ({
    type: actionTypes.SORT_SUCCESS_STORY_RESET,
  }),
}
