import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {SortSearchActionModel, SortWebsiteSectionModel} from '../Model/WebsiteSectionModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getWebsiteSectionSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getWebsiteSection, params)
    yield put(actions.getWebsiteSectionSuccess(response?.data?.data))
    yield put(actions.getWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getWebsiteSectionFinish())
  }
}

function* getAllWebsiteSectionSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllWebsiteSection)
    yield put(actions.getAllWebsiteSectionSuccess(response?.data?.data))
    yield put(actions.getAllWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllWebsiteSectionFinish())
  }
}

function* getWebsiteBlockOptionSaga() {
  try {
    const response: ResponseModel = yield call(service.getWebsiteBlockOption)
    yield put(actions.getWebsiteBlockOptionSuccess(response?.data?.data?.websiteBlockItem))
    yield put(actions.getWebsiteBlockOptionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getWebsiteBlockOptionFinish())
  }
}

function* addWebsiteSectionSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addWebsiteSection, body)
    yield put(actions.addWebsiteSectionSuccess(response.data?.data))
    yield put(actions.addWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addWebsiteSectionFinish())
  }
}

function* enableWebsiteSectionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableWebsiteSection, selectedUsers)
    yield put(actions.enableWebsiteSectionSuccess(response?.data))
    yield put(actions.enableWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableWebsiteSectionFinish())
  }
}

function* disableWebsiteSectionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableWebsiteSection, selectedUsers)
    yield put(actions.disableWebsiteSectionSuccess(response?.data))
    yield put(actions.disableWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableWebsiteSectionFinish())
  }
}

function* singleEnableWebsiteSectionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableWebsiteSection, selectedUsers)
    yield put(actions.singleEnableWebsiteSectionSuccess(response?.data))
    yield put(actions.singleEnableWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableWebsiteSectionFinish())
  }
}

function* singleDisableWebsiteSectionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableWebsiteSection, selectedUsers)
    yield put(actions.singleDisableWebsiteSectionSuccess(response?.data))
    yield put(actions.singleDisableWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableWebsiteSectionFinish())
  }
}

function* updateWebsiteSectionSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateWebsiteSection,
      body,
      action.payload?.id
    )
    yield put(actions.updateWebsiteSectionSuccess(response.data?.data))
    yield put(actions.updateWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateWebsiteSectionFinish())
  }
}

function* deleteWebsiteSectionSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteWebsiteSection, body)
    yield put(actions.deleteWebsiteSectionSuccess(response.data?.data))
    yield put(actions.deleteWebsiteSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteWebsiteSectionFinish())
  }
}

function* sortWebsiteSection(action: SortSearchActionModel) {
  try {
    const body: SortWebsiteSectionModel = action.payload
    const response: ResponseModel = yield call(service.sortWebsiteSection, body)

    yield put({
      type: actionTypes.SORT_WEBSITE_SECTION_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_WEBSITE_SECTION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_WEBSITE_SECTION_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_WEBSITE_SECTION_START, getWebsiteSectionSaga)
  yield takeLatest(actionTypes.GET_ALL_WEBSITE_SECTION_START, getAllWebsiteSectionSaga)
  yield takeLatest(actionTypes.ADD_WEBSITE_SECTION_START, addWebsiteSectionSaga)
  yield takeLatest(actionTypes.UPDATE_WEBSITE_SECTION_START, updateWebsiteSectionSaga)
  yield takeLatest(actionTypes.DELETE_WEBSITE_SECTION_START, deleteWebsiteSectionSaga)
  yield takeLatest(actionTypes.ENABLE_WEBSITE_SECTION_REQUEST, enableWebsiteSectionSaga)
  yield takeLatest(actionTypes.DISABLE_WEBSITE_SECTION_REQUEST, disableWebsiteSectionSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_WEBSITE_SECTION_REQUEST,
    singleEnableWebsiteSectionSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_WEBSITE_SECTION_REQUEST,
    singleDisableWebsiteSectionSaga
  )
  yield takeLatest(actionTypes.SORT_WEBSITE_SECTION_START, sortWebsiteSection)
  yield takeLatest(actionTypes.GET_WEBSITE_BLOCK_OPTION_START, getWebsiteBlockOptionSaga)
}
