import {Action} from 'redux'
import {WebsiteServiceModel} from '../Model/WebsiteServiceModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialWebsiteServiceState: IWebsiteServiceState = {
  data: {
    websiteService: [],
    meta: [],
  },
  sortWebsiteServiceData: [],
  websiteServiceList: {websiteService: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IWebsiteServiceState {
  data?: {
    websiteService?: WebsiteServiceModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortWebsiteServiceData?: IWebsiteServiceState[]
  websiteServiceList?: {websiteService: WebsiteServiceModel[]}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IWebsiteServiceState = initialWebsiteServiceState,
  action: ActionWithPayload<IWebsiteServiceState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_WEBSITE_SERVICE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_WEBSITE_SERVICE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        websiteServiceList: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_WEBSITE_SERVICE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET WebsiteService DATA
    case actionTypes.GET_WEBSITE_SERVICE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_WEBSITE_SERVICE_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_WEBSITE_SERVICE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add WebsiteService
    case actionTypes.ADD_WEBSITE_SERVICE_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_WEBSITE_SERVICE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_WEBSITE_SERVICE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_WEBSITE_SERVICE: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update WebsiteService
    case actionTypes.UPDATE_WEBSITE_SERVICE_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_WEBSITE_SERVICE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_WEBSITE_SERVICE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_WEBSITE_SERVICE_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_WEBSITE_SERVICE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_WEBSITE_SERVICE_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_WEBSITE_SERVICE_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_WEBSITE_SERVICE_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_WEBSITE_SERVICE_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_WEBSITE_SERVICE_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_WEBSITE_SERVICE_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_WEBSITE_SERVICE_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_WEBSITE_SERVICE_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_WEBSITE_SERVICE_SUCCESS: {
      const changedData: any = action.payload?.data?.websiteService
      let newData = state?.data?.websiteService?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, websiteService: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_WEBSITE_SERVICE_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_WEBSITE_SERVICE_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_WEBSITE_SERVICE_SUCCESS: {
      const changedData: any = action.payload?.data?.websiteService
      let newData = state?.data?.websiteService?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, websiteService: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_WEBSITE_SERVICE_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_WEBSITE_SERVICE_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_WEBSITE_SERVICE_SUCCESS: {
      return {
        ...state,
        sortWebsiteServiceData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_WEBSITE_SERVICE_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_WEBSITE_SERVICE_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortWebsiteServiceData: [],
      }
    }

    default:
      return state
  }
}
