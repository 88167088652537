import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {WebsiteServiceModel, SortWebsiteServiceModel} from '../Model/WebsiteServiceModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const WEBSITE_SERVICE_API = `${API_URL}/websiteService`

export const service = {
  getWebsiteService: (params: ParamsModel) => {
    return axios.get(WEBSITE_SERVICE_API, {params})
  },
  getAllWebsiteService: () => {
    return axios.get(`${WEBSITE_SERVICE_API}/list`)
  },

  addWebsiteService: (data: any) => {
    return axios.post(WEBSITE_SERVICE_API, data)
  },

  updateWebsiteService: (body: WebsiteServiceModel, id: string) => {
    return axios.patch(`${WEBSITE_SERVICE_API}/${id}`, body)
  },

  deleteWebsiteService: (data: {id: string}) => {
    return axios.delete(WEBSITE_SERVICE_API, {data})
  },

  enableWebsiteService: (data: Array<string>) => {
    const selectedWebsiteService = {
      websiteServiceId: data,
    }
    return axios.patch(`${WEBSITE_SERVICE_API}/enable`, selectedWebsiteService)
  },

  disableWebsiteService: (data: Array<string>) => {
    const selectedWebsiteService = {
      websiteServiceId: data,
    }
    return axios.patch(`${WEBSITE_SERVICE_API}/disable`, selectedWebsiteService)
  },

  singleEnableWebsiteService: (data: Array<string>) => {
    const selectedWebsiteService = {
      websiteServiceId: [data],
    }
    return axios.patch(`${WEBSITE_SERVICE_API}/enable`, selectedWebsiteService)
  },

  singleDisableWebsiteService: (data: Array<string>) => {
    const selectedWebsiteService = {
      websiteServiceId: [data],
    }
    return axios.patch(`${WEBSITE_SERVICE_API}/disable`, selectedWebsiteService)
  },
  sortWebsiteService: (body: SortWebsiteServiceModel) => {
    return axios.patch(`${WEBSITE_SERVICE_API}/sort`, body)
  },
}
