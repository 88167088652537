import {OptionModel} from 'src/app/modules/aboutBank/components/Model'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeletePayoutLocationModel, SortPayoutLocationModel} from '../Model'
import {PayoutLocationModel} from '../Model/PayoutLocationModel'
import {actionTypes} from './constants'
export const actions = {
  // get PayoutLocation
  getAllPayoutLocation: () => ({
    type: actionTypes.GET_ALL_PAYOUT_LOCATION_START,
  }),
  getAllPayoutLocationSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_ALL_PAYOUT_LOCATION_SUCCESS,
    payload: data,
  }),
  getAllPayoutLocationFinish: () => ({
    type: actionTypes.GET_ALL_PAYOUT_LOCATION_FINISH,
  }),

  // get PayoutLocation DATA
  getPayoutLocation: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_PAYOUT_LOCATION_START,
    payload: params,
  }),
  getPayoutLocationSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_PAYOUT_LOCATION_SUCCESS,
    payload: data,
  }),
  getPayoutLocationFinish: () => ({
    type: actionTypes.GET_PAYOUT_LOCATION_FINISH,
  }),

  // create key
  addPayoutLocation: (data: PayoutLocationModel) => ({
    type: actionTypes.ADD_PAYOUT_LOCATION_START,
    payload: data,
  }),
  addPayoutLocationSuccess: (task: any) => ({
    type: actionTypes.ADD_PAYOUT_LOCATION_SUCCESS,
    payload: task,
  }),
  addPayoutLocationFinish: () => ({
    type: actionTypes.ADD_PAYOUT_LOCATION_FINISH,
  }),
  resetPayoutLocation: () => ({
    type: actionTypes.RESET_PAYOUT_LOCATION,
  }),

  //Update PayoutLocation
  updatePayoutLocation: (data: PayoutLocationModel, id: string) => ({
    type: actionTypes.UPDATE_PAYOUT_LOCATION_START,
    payload: {data, id},
  }),

  updatePayoutLocationSuccess: (data: PayoutLocationModel) => ({
    type: actionTypes.UPDATE_PAYOUT_LOCATION_SUCCESS,
    payload: data,
  }),

  updatePayoutLocationFinish: () => ({
    type: actionTypes.UPDATE_PAYOUT_LOCATION_FINISH,
  }),

  // delete key
  deletePayoutLocation: (data: DeletePayoutLocationModel[]) => ({
    type: actionTypes.DELETE_PAYOUT_LOCATION_START,
    payload: {payoutlocationsId: data},
  }),
  deletePayoutLocationSuccess: (data: any) => ({
    type: actionTypes.DELETE_PAYOUT_LOCATION_SUCCESS,
    payload: data,
  }),
  deletePayoutLocationFinish: () => ({
    type: actionTypes.DELETE_PAYOUT_LOCATION_FINISH,
  }),

  //Enable PayoutLocation
  enablePayoutLocation: (data: any) => ({
    type: actionTypes.ENABLE_PAYOUT_LOCATION_REQUEST,
    payload: {data},
  }),

  enablePayoutLocationSuccess: (task: any) => ({
    type: actionTypes.ENABLE_PAYOUT_LOCATION_SUCCESS,
    payload: task,
  }),
  enablePayoutLocationFinish: () => ({
    type: actionTypes.ENABLE_PAYOUT_LOCATION_FINISH,
  }),

  //Disable PayoutLocation
  disablePayoutLocation: (data: any) => ({
    type: actionTypes.DISABLE_PAYOUT_LOCATION_REQUEST,
    payload: {data},
  }),

  disablePayoutLocationSuccess: (task: any) => ({
    type: actionTypes.DISABLE_PAYOUT_LOCATION_SUCCESS,
    payload: task,
  }),
  disablePayoutLocationFinish: () => ({
    type: actionTypes.DISABLE_PAYOUT_LOCATION_FINISH,
  }),

  //Enable PayoutLocation
  singleEnablePayoutLocation: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_PAYOUT_LOCATION_REQUEST,
    payload: {data},
  }),

  singleEnablePayoutLocationSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_PAYOUT_LOCATION_SUCCESS,
    payload: task,
  }),
  singleEnablePayoutLocationFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_PAYOUT_LOCATION_FINISH,
  }),

  //Disable PayoutLocation
  singleDisablePayoutLocation: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_PAYOUT_LOCATION_REQUEST,
    payload: {data},
  }),

  singleDisablePayoutLocationSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_PAYOUT_LOCATION_SUCCESS,
    payload: task,
  }),
  singleDisablePayoutLocationFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_PAYOUT_LOCATION_FINISH,
  }),
  // sort
  sortPayoutLocation: (data: SortPayoutLocationModel | any) => ({
    type: actionTypes.SORT_PAYOUT_LOCATION_START,
    payload: data,
  }),
  sortPayoutLocationSuccess: (data: Array<PayoutLocationModel>) => ({
    type: actionTypes.SORT_PAYOUT_LOCATION_SUCCESS,
    payload: data,
  }),
  sortPayoutLocationFinish: () => ({
    type: actionTypes.SORT_PAYOUT_LOCATION_FINISH,
  }),
  sortPayoutLocationReset: () => ({
    type: actionTypes.SORT_PAYOUT_LOCATION_RESET,
  }),
}
