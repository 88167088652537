export const actionTypes = {
  // get
  GET_ALL_WEBSITE_SERVICE_START: 'GET_ALL_WEBSITE_SERVICE_START',
  GET_ALL_WEBSITE_SERVICE_SUCCESS: 'GET_ALL_WEBSITE_SERVICE_SUCCESS',
  GET_ALL_WEBSITE_SERVICE_FINISH: 'GET_ALL_WEBSITE_SERVICE_FINISH',

  // get website service
  GET_WEBSITE_SERVICE_START: 'GET_WEBSITE_SERVICE_START',
  GET_WEBSITE_SERVICE_SUCCESS: 'GET_WEBSITE_SERVICE_SUCCESS',
  GET_WEBSITE_SERVICE_FINISH: 'GET_WEBSITE_SERVICE_FINISH',

  // add website
  ADD_WEBSITE_SERVICE_START: 'ADD_WEBSITE_SERVICE_START',
  ADD_WEBSITE_SERVICE_SUCCESS: 'ADD_WEBSITE_SERVICE_SUCCESS',
  ADD_WEBSITE_SERVICE_FINISH: 'ADD_WEBSITE_SERVICE_FINISH',
  RESET_WEBSITE_SERVICE: 'RESET_WEBSITE_SERVICE',

  // update WEBSITE_SERVICE
  UPDATE_WEBSITE_SERVICE_START: 'UPDATE_WEBSITE_SERVICE_START',
  UPDATE_WEBSITE_SERVICE_SUCCESS: 'UPDATE_WEBSITE_SERVICE_SUCCESS',
  UPDATE_WEBSITE_SERVICE_FINISH: 'UPDATE_WEBSITE_SERVICE_FINISH',

  // delete WEBSITE_SERVICE
  DELETE_WEBSITE_SERVICE_START: 'DELETE_WEBSITE_SERVICE_START',
  DELETE_WEBSITE_SERVICE_SUCCESS: 'DELETE_WEBSITE_SERVICE_SUCCESS',
  DELETE_WEBSITE_SERVICE_FINISH: 'DELETE_WEBSITE_SERVICE_FINISH',

  // Enable WEBSITE_SERVICE
  ENABLE_WEBSITE_SERVICE_REQUEST: 'ENABLE_WEBSITE_SERVICE_REQUEST',
  ENABLE_WEBSITE_SERVICE_SUCCESS: 'ENABLE_WEBSITE_SERVICE_SUCCESS',
  ENABLE_WEBSITE_SERVICE_FINISH: 'ENABLE_WEBSITE_SERVICE_FINISH',

  // Disable WEBSITE_SERVICE
  DISABLE_WEBSITE_SERVICE_REQUEST: 'DISABLE_WEBSITE_SERVICE_REQUEST',
  DISABLE_WEBSITE_SERVICE_SUCCESS: 'DISABLE_WEBSITE_SERVICE_SUCCESS',
  DISABLE_WEBSITE_SERVICE_FINISH: 'DISABLE_WEBSITE_SERVICE_FINISH',

  // Enable WEBSITE_SERVICE
  SINGLE_ENABLE_WEBSITE_SERVICE_REQUEST: 'SINGLE_ENABLE_WEBSITE_SERVICE_REQUEST',
  SINGLE_ENABLE_WEBSITE_SERVICE_SUCCESS: 'SINGLE_ENABLE_WEBSITE_SERVICE_SUCCESS',
  SINGLE_ENABLE_WEBSITE_SERVICE_FINISH: 'SINGLE_ENABLE_WEBSITE_SERVICE_FINISH',

  // Disable WEBSITE_SERVICE
  SINGLE_DISABLE_WEBSITE_SERVICE_REQUEST: 'SINGLE_DISABLE_WEBSITE_SERVICE_REQUEST',
  SINGLE_DISABLE_WEBSITE_SERVICE_SUCCESS: 'SINGLE_DISABLE_WEBSITE_SERVICE_SUCCESS',
  SINGLE_DISABLE_WEBSITE_SERVICE_FINISH: 'SINGLE_DISABLE_WEBSITE_SERVICE_FINISH',

  // sort
  SORT_WEBSITE_SERVICE_START: 'SORT_WEBSITE_SERVICE_START',
  SORT_WEBSITE_SERVICE_SUCCESS: 'SORT_WEBSITE_SERVICE_SUCCESS',
  SORT_WEBSITE_SERVICE_FINISH: 'SORT_WEBSITE_SERVICE_FINISH',
  SORT_WEBSITE_SERVICE_RESET: 'SORT_WEBSITE_SERVICE_RESET',
}
